/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'GalleriesUsersFilter/updateFilter',
        reset: 'GalleriesUsersFilter/resetFilter',
        resetState: 'GalleriesUsersFilter/resetFilterState',
        removeSelected: 'GalleriesUsersFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();
        filter.createSearchEntity('fullName');
        filter.setPlaceholder('fullName', 'По имени');
        filter.setName('fullName', 'search_fullName');
        filter.setPreviewName('fullName', 'Имя');
        filter.setIconClassName('fullName', 'icon-user-search');
        filter.setAction('fullName', 'GalleryUsers/updateSearch');

        filter.createSearchEntity('email');
        filter.setPlaceholder('email', 'По email');
        filter.setName('email', 'search_email');
        filter.setPreviewName('email', 'Email');
        filter.setIconClassName('email', 'icon-email');
        filter.setAction('email', 'GalleryUsers/updateSearch');

        filter.createSearchEntity('phone');
        filter.setPlaceholder('phone', 'По телефону');
        filter.setName('phone', 'search_phone');
        filter.setPreviewName('phone', 'Тел');
        filter.setIconClassName('phone', 'icon-phone');
        filter.setAction('phone', 'GalleryUsers/updateSearch');

        this.filterModel = filter.filterModel;
    }
}
