
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconFont from '@/components/icons/IconFont.vue';
import UserModule from '@/admin/store/user';
import RoleModule, { AccessByRouter, RoleEnum } from '@/admin/store/role';

@Component({
    components: { IconFont },
})
export default class TabsNavIconsComponent extends Vue {
    @Prop({ required: true }) readonly items!: any;
    @Prop({ required: false, default: 'actionHandler' }) readonly actionName!: string;
    @Prop({ required: false }) readonly params!: any;
    @Prop({ required: true, default: '1' }) readonly activeId!: string;
    @Prop({ required: false, default: '1' }) readonly instanceId!: string;
    @Prop({ required: false }) readonly parentLvl!: number;

    translatePos = 0;
    touchPos = 0;
    overflowRatio = 10;
    toRight = 'to-right';
    toLeft = 'to-left';
    screenWidth = 0;
    offsetWidth = 0;
    currentId: string | null = null;
    posBeforeScroll = 0;
    isiOs = false;

    get role(): RoleEnum | null {
        return UserModule.role;
    }

    get accessByRouterName(): AccessByRouter {
        return RoleModule.accessByRouterName;
    }

    beforeMount(): void {
        this.currentId = this.activeId;
    }

    updated(): void {
        this.currentId = this.activeId;
    }
}
