<template>
    <div
        v-if="data.hidden !== true"
        class="cp-input-main__wrapper"
        :class="{ disabled: data.disabled || disabled, 'required-field-wrapper': data.required }"
    >
        <label>
            <span
                v-if="data.title"
                class="mr-input-default__title cd-form-input-label"
                :class="{ 'cd-form-input-label-active': isLabelActive || data.value || data.value === 0 }"
            >
                {{ data.title }}
            </span>

            <span class="mr-input-default">
                <input
                    :type="data.type"
                    :placeholder="data.title && isLabelActive ? data.placeholder : ''"
                    :value="data.value"
                    class="cp-input-radius-50"
                    :class="data.error.class"
                    :maxlength="data.size"
                    :disabled="data.disabled || disabled"
                    :name="data.key"
                    :autocomplete="data.autocomplete"
                    @input="onInput"
                    @change="onInput"
                    @focusin="addClassIn"
                    @focusout="removeClassIn"
                />
                <span
                    v-if="data.clearAction && data.value"
                    class="cp-cursor-pointer mr-input-default__clear"
                    @click="resetValue"
                >
                    <icon-font size="11" icon="close" />
                </span>
                <span v-if="data.additional" class="mr-input-default__additional">{{ data.additional }}</span>
            </span>
        </label>

        <span class="cp-error-message">{{ data.error.message }}</span>
    </div>
</template>
<script>
import IconFont from '@/components/icons/IconFont';

export default {
    components: {
        IconFont,
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    key: 'login',
                    type: 'text',
                    title: 'Логин',
                    placeholder: 'Введите имя',
                    value: null,
                    action: 'user/auth/updateLogin',
                    errorHandler: 'user/auth/setValidateState',
                    required: true,
                    size: 250,
                    mask: {
                        regex: '[a-zA-ZА-Яа-я+\\s]+',
                        placeholder: ' ',
                        minChars: 1,
                    },
                    error: {
                        class: '',
                        message: '',
                    },
                    validation: {
                        empty: 'Поле не заполнено',
                        incorrect: 'Некорректное имя',
                    },
                    clearAction: false,
                };
            },
        },
        additionalData: null,
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            classes: {
                in: 'cd-label-active',
            },
            isLabelActive: false,
        };
    },
    methods: {
        addClassIn() {
            this.isLabelActive = true;
        },
        removeClassIn() {
            this.isLabelActive = false;
        },
        onInput(e) {
            if (this.additionalData != null) {
                this.$emit('input', { value: e.target.value, param: this.additionalData });
            } else {
                this.$emit('input', e.target.value);
            }

            // if (this.data.errorHandler) {
            //     this.$store.dispatch(this.data.errorHandler, {index: this.data.key, field: this.data}, {root: true});
            // }
        },
        resetValue() {
            this.$emit('input', '');
        },
    },
};
</script>
