
import { Component } from 'vue-property-decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import InputBlock from '@/components/form/InputMain.vue';
import InputLocalization from '@/components/input/InputLocalization.vue';
import LocalizationLayout from '@/admin/layouts/LocalizationLayout.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import TextareaCustom from '@/components/form/Textarea.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';

import Inputmask from 'inputmask';
import SellersGalleryEntity from '@/admin/store/sellers/galleries/entity';
import UserModule from '@/admin/store/user';
import EditorModalLocalization from '@/components/modules/_editorModalLocalization.vue';
import Loading from '@/components/Loading.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp, slugRegExp } from '@/constants';
import OuterAddition from '@/components/form/OuterAddition.vue';
import Requisites from './requisites.vue';
import { Localization, LocalizationParams } from '@/interfaces/localization';
import { imageToFile } from '@/lib/utils/Utils';

@Component({
    components: {
        OuterAddition,
        RemoveModal,
        TabsNav,
        BtnDefault,
        InputBlock,
        InputLocalization,
        SelectCustom,
        BtnSubmit,
        ImageBlock,
        EditorModalLocalization,
        Loading,
        InputSuggestions,
        TextareaCustom,
        CheckboxCustom,
        Requisites,
    },
})
export default class SellersGalleryCard extends LocalizationLayout {
    returnTitleData = {
        icon: 'bank',
        title: this.SellersGalleryName,
    };

    get model() {
        return SellersGalleryEntity.model;
    }

    get SellersGalleryName() {
        return SellersGalleryEntity.galleryName;
    }

    get modalParams() {
        return SellersGalleryEntity.modalParams;
    }

    get isLoading() {
        return SellersGalleryEntity.isLoading;
    }

    get isRoleSuperadmin(): boolean {
        return UserModule.isSuperAdmin;
    }

    getFormattedNumberString(value: string): string {
        return formatNumberSpaced(value);
    }

    updateName(params: LocalizationParams): void {
        SellersGalleryEntity.updateLocalizationField({ ...params, field: 'name' });
    }

    updateSelectedCountry(selected: { id: number; value: string }) {
        SellersGalleryEntity.updateSelectedCountry(selected);
    }

    updateSearchCountry(search: string) {
        SellersGalleryEntity.searchCountry(search);
    }

    updateSelectedCity(selected: InputSuggestionItem) {
        SellersGalleryEntity.updateSuggestionsSelectedByType({ selected, type: 'city' });
    }

    updateSearchCity(search: string) {
        SellersGalleryEntity.searchCity(search);
    }

    updateAddress(params: LocalizationParams): void {
        SellersGalleryEntity.updateLocalizationField({ ...params, field: 'address' });
    }

    updateSite(value: string) {
        SellersGalleryEntity.updateSite(value);
    }

    updatePhone(value: string) {
        SellersGalleryEntity.updatePhone(value);
    }

    updateEmail(value: string) {
        SellersGalleryEntity.updateEmail(value);
    }

    updateSort(value: string) {
        SellersGalleryEntity.updateSortOrder(formatRemoveSpaces(value));
    }

    updateSlug(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'slug', value });
    }

    updateSiteCommission(value: string) {
        SellersGalleryEntity.updateSiteCommission(formatRemoveSpaces(value));
    }

    updateTermsValue(params: Localization): void {
        SellersGalleryEntity.SET_LOCALIZATION_BY_FIELD({ fieldName: 'terms', data: params });
    }

    updateDescriptionValue(params: Localization): void {
        SellersGalleryEntity.SET_LOCALIZATION_BY_FIELD({ fieldName: 'description', data: params });
    }

    updateTopSeller(params: { name: string; id: number; bool: boolean }) {
        SellersGalleryEntity.updateTopSeller(params.bool);
    }

    async handleImage(file: File) {
        const toFile = await imageToFile(file);

        SellersGalleryEntity.updatePhotoFile(toFile);
    }

    removePreview() {
        SellersGalleryEntity.removeImagePreview();
    }

    closeModal() {
        SellersGalleryEntity.toggleModal({ key: 'terms', bool: false });
        SellersGalleryEntity.toggleModal({ key: 'description', bool: false });
    }

    openModal(key: string) {
        SellersGalleryEntity.toggleModal({ key, bool: true });
    }

    returnBefore() {
        this.$router.push({ name: 'galleries' });
    }

    send() {
        if (this.$route.params.galleryId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        SellersGalleryEntity.sendEdit();
    }

    sendAdd() {
        SellersGalleryEntity.sendAdd({ name: 'galleries' });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }

        const settingsMaskSlug = new Inputmask({ regex: slugRegExp, showMaskOnHover: false });
        const inputsSlug = this.$el.getElementsByClassName('j_slug_mask_wrapper');

        for (const item of Object.values(inputsSlug)) {
            settingsMaskSlug.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async created() {
        if (this.$route.params.galleryId) {
            this.returnTitleData = SellersGalleryEntity.titleReturnData.edit;

            return;
        }

        SellersGalleryEntity.updateIsLoading(false);
        this.returnTitleData = SellersGalleryEntity.titleReturnData.create;
    }

    mounted() {
        this.initMask();
    }
}
