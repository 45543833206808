
import { Component, Vue, Prop } from 'vue-property-decorator';
import IconFont from '@/components/icons/IconFont.vue';
import BtnBorder from '@/components/buttons/BtnBorder.vue';
import ModalBtnBottom from '@/admin/layouts/ModalBtnBottom.vue';
import { strings } from '@/lib/stringConst';

interface WarningText {
    no: string;
    yes: string;
    title: string;
    description: string;
}

const DEFAULT_TEXTS: WarningText = {
    no: strings.btnCancel,
    yes: strings.btnOk,
    title: strings.warning,
    description: '',
};

@Component({
    name: 'WarningModal',
    components: {
        IconFont,
        BtnBorder,
        ModalBtnBottom,
    },
})
export default class ModalWarning extends Vue {
    @Prop({ type: Object, default: () => ({}) }) warningText!: Partial<WarningText>;

    get texts(): WarningText {
        return { ...DEFAULT_TEXTS, ...this.warningText };
    }

    closeModal() {
        this.$emit('close', false);
    }

    actionOnKeyup(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.closeModal();
        } else if (event.key === 'Enter') {
            this.$emit('yes');
        }
    }

    mounted(): void {
        window.addEventListener('keyup', this.actionOnKeyup, false);
    }

    beforeDestroy(): void {
        window.removeEventListener('keyup', this.actionOnKeyup, true);
    }
}
