
import { Component, Prop, Vue } from 'vue-property-decorator';
import BtnDefault from '@/components/BtnDefault.vue';
import ModalBlock from '@/components/Modal.vue';
import { makeDeleteRequest } from '@/api/helpers';
import WarningModal from '@/components/modules/Warning.vue';
import { strings } from '@/lib/stringConst';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getApiErrorMessage } from '@/lib/utils/Utils';

type Entity =
    | 'user'
    | 'country'
    | 'city'
    | 'auction-house'
    | 'painter'
    | 'painter_work'
    | 'gallery'
    | 'category'
    | 'auction'
    | 'lot'
    | 'bid'
    | 'color'
    | 'currency'
    | 'specialization'
    | 'faq'
    | 'news'
    | 'address'
    | 'slider_content';

interface EntityObj {
    name: string;
    redirect?: string;
    apiPrefix?: string;
    apiName?: string;
}

const ENTITY_INFO: Record<Entity, EntityObj> = {
    user: {
        name: 'пользователя',
        redirect: 'users',
    },
    country: {
        name: 'страну',
        redirect: 'settings_countries',
    },
    city: {
        name: 'город',
        redirect: 'settings_cities',
    },
    'auction-house': {
        name: 'аукционный дом',
        apiPrefix: 'seller/',
        redirect: 'auction_houses',
    },
    gallery: {
        name: 'галерею',
        apiPrefix: 'seller/',
        redirect: 'galleries',
    },
    painter: {
        name: 'автора',
        apiPrefix: 'seller/',
        redirect: 'painters',
    },
    painter_work: {
        name: 'работу автора',
        apiName: 'lot',
    },
    category: {
        name: 'категорию',
        redirect: 'settings_categories',
    },
    auction: {
        name: 'аукцион',
        redirect: 'auctions',
    },
    lot: {
        name: 'лот',
    },
    bid: {
        name: 'ставку',
        redirect: 'lot_bids',
    },
    color: {
        name: 'цвет',
        redirect: 'settings_colors',
    },
    currency: {
        name: 'валюту',
        redirect: 'settings_currencies',
    },
    specialization: {
        name: 'специализацию',
        redirect: 'settings_specialties',
    },
    faq: {
        name: 'вопрос',
        redirect: 'content_faq',
    },
    news: {
        name: 'новость',
        redirect: 'content_news',
    },
    address: {
        name: 'адрес',
        redirect: '',
    },
    slider_content: {
        name: 'слайд',
        redirect: 'content_main_page',
    },
};

@Component({
    name: 'RemoveModal',
    components: {
        WarningModal,
        BtnDefault,
        ModalBlock,
    },
})
export default class EditorModal extends Vue {
    @Prop({ type: String, required: true }) entity!: Entity;
    @Prop({ type: [Number, String], required: true }) id!: number;

    modal = false;
    notifyParams = {
        success: {
            message: `Вы успешно удалили ${this.entityObj.name}!`,
            type: 'ok',
        },
        fail: {
            message: `Не удалось удалить ${this.entityObj.name}.`,
            type: 'fail',
        },
    };

    get entityObj(): EntityObj {
        return ENTITY_INFO[this.entity];
    }

    get texts() {
        return {
            yes: strings.btnDelete,
            description: `Вы уверены, что хотите удалить ${this.entityObj.name}?`,
        };
    }

    get endpoint(): string {
        return `/api/admin/${this.entityObj.apiPrefix || ''}${this.entityObj.apiName || this.entity}/${this.id}/`;
    }

    async remove(): Promise<void> {
        try {
            this.$emit('remove');
            const res = await makeDeleteRequest(this.endpoint);
            this.$emit('removed');
            this.closeModal();

            let isSuccess = res.data?.result?.status === 'ok';
            ResponseHandlerModule.showNotify(isSuccess ? this.notifyParams.success : this.notifyParams.fail);

            if (isSuccess && this.entityObj.redirect) {
                this.$router.push({ name: this.entityObj.redirect });
            }
        } catch (error) {
            this.closeModal();
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    closeModal() {
        this.modal = false;
    }
}
