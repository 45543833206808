
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar, faChevronLeft, faChevronRight);

import DatePickerMixin from '@/components/form/DatePickerMixin';

@Component({
    components: {
        FontAwesomeIcon,
    },
})
export default class DatePickerComponent extends Mixins(DatePickerMixin) {
    @Prop() readonly disabled!: boolean;
}
