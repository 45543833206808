import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getGalleryList } from '@/admin/api/gallery';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { initCountryFilter } from '@/lib/utils/filter';
import { tabsNav } from './tabs';
import filterModel from './filter';
import { Filter } from '@/lib/layouts/page/filter.interface';
import GalleryFilter from './filterEntity';
import {
    FilterInputSuggestionsParamsInterface,
    FilterSearchParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { getCitiesList } from '@/admin/api/city';
import { processListForSelect } from '@/lib/utils/form';
import { getCountriesList } from '@/admin/api/country';
import { generateSort } from '@/lib/utils/Utils';
import { YesNoMap } from '@/constants';

export const MODULE_NAME = 'sellersGallery';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SellersGalleriesModule extends PageBaseModule {
    filter: Filter;
    tabsNav = tabsNav;

    constructor(module: SellersGalleriesModule) {
        super(module);
        const page = new PageEntity();

        page.setTitle('Галереи');
        page.setSortName('sellersGallerySort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(GalleryFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('sellersGalleryFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Action({ rawError: true })
    async init() {
        this.setDefaultSort();
        this.SET_FILTER_REQUEST_SORT(await generateSort({ id: 'name', value: 'ASC' }));

        this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();
        const result = await getGalleryList(
            this.pageSettings.pageCurrent,
            sort,
            this.filter.filterSettings.filter,
            this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};

            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                if (i === 'photo') {
                    cell = cell ? `<img src="${cell}" width="80" class="cp-input-radius-25" />` : '';
                }

                if (i === 'topSeller') {
                    cell = YesNoMap.get(cell);
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    async initCountry() {
        const countries = await initCountryFilter();

        return countries;
    }

    @Action({ rawError: true })
    async updateCountrySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        let requestFilter = `&filters[0][id]=name&filters[0][value]=${params.value}`;
        requestFilter += this.filter.filterSettings.filterModel.country.current?.id
            ? `&filters[1][id]=country&filters[1][value]=${this.filter.filterSettings.filterModel.country.current.id}`
            : '';

        const table = await getCountriesList(1, this.pageSettings.filterRequestSort ?? '', requestFilter, 50);
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updateSuggestionsSelectedCountry(params: FilterInputSuggestionsParamsInterface) {
        await this.filter.updateSuggestionsSelected(params);
        await this.filter.updateSuggestionsSelected({ key: 'city', value: { id: 0, value: '' } });

        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        await this.filter.updateSuggestionsSelected(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateCitySuggestionsValue(params: { key: string; value: string }) {
        this.filter.updateSuggestionsValue(params);

        let requestFilter = `&filters[0][id]=name&filters[0][value]=${params.value}`;
        requestFilter += this.filter.filterSettings.filterModel.country.current?.id
            ? `&filters[1][id]=country&filters[1][value]=${this.filter.filterSettings.filterModel.country.current.id}`
            : '';

        const table = await getCitiesList(1, this.pageSettings.filterRequestSort ?? '', requestFilter, 50);
        this.filter.updateSuggestionsHintsList({ key: params.key, value: processListForSelect(table.rows) });
    }
}

export default getModule(SellersGalleriesModule);
