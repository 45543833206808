export const strings = {
    SUCCESS_UPDATE: 'Изменения сохранены',
    btnSave: 'Сохранить',
    btnCreate: 'Создать',
    btnAdd: 'Добавить',
    btnOk: 'Подтвердить',
    btnApply: 'Применить',
    btnCancel: 'Отменить',
    btnDelete: 'Удалить',
    warning: 'Внимание!',
};
