
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ModalBtnBottomLayout extends Vue {
    @Prop({ required: false }) readonly position?: number | string;

    get classPosition() {
        let classPosition = this.position === 'end' ? 'mr_modal-btn__end' : '';
        classPosition = this.position === 'start' ? 'mr_modal-btn__start' : classPosition;
        classPosition = this.position === 'between' ? 'mr_modal-btn__between' : classPosition;

        return classPosition;
    }
}
