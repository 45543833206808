
import { SelectItem } from '@/lib/formFactory/select.interface';
import { Mixins, Component } from 'vue-property-decorator';

import InputSuggestionsMixin from './InputSuggestionsMixin';

@Component
export default class InputSuggestionsComponent extends Mixins(InputSuggestionsMixin) {
    updateSearch(e: Event) {
        const value = (e.target as HTMLInputElement).value;
        this.isShowHints = true;
        this.$emit('input', value);

        if (!value) {
            this.$emit('click', { id: 0, value: '' });
        }
    }
    selectValue(item: SelectItem) {
        this.$emit('click', item);
        this.isShowHints = false;
    }
}
