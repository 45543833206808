import { getModule, Module, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import store from '@/admin/store';
import { getOrdersList, getOrdersStatuses } from '@/admin/api/orders';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareTable } from '@/lib/Orders';
import { Filter } from '@/lib/layouts/page/filter.interface';
import FilterModel from './ordersFilter';
import FilterEntity from './ordersFilterEntity';
import { PageSort } from '@/lib/layouts/page/page.interface';
import {
    FilterDateParamsInterface,
    FilterMultiSelectParamsInterface,
    FilterSearchParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';

const MODULE_NAME = 'GalleryOrders';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class GalleryOrdersModule extends PageBaseModule {
    filter: Filter;
    statuses: { id: string; name: string }[] = [];

    constructor(module: GalleryOrdersModule) {
        super(module);

        const page = new PageEntity();
        this.pageSettings = page.values;

        const filter = new FilterModel();
        this.filter = getModule(FilterEntity);
        this.filter.setTemplateClassName('template-lg');
        this.filter.setBtnClassName('row-5');
        this.filter.setFilterName('GalleryOrdersFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;
        window.localStorage.galleryOrdersSort = JSON.stringify(sorts);
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Action({ rawError: true })
    async init(galleryId: string) {
        this.context.commit(
            'SET_SORT',
            window.localStorage.galleryOrdersSort ? JSON.parse(window.localStorage.galleryOrdersSort) : {},
        );
        this.context.commit('SET_STATUSES', await getOrdersStatuses());

        await this.filter.init();

        await this.getTable(galleryId);
        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    async initStatuses() {
        const result = this.statuses.map((status) => {
            return {
                checked: false,
                id: status.id,
                name: status.name,
                value: status.id,
            };
        });

        return result;
    }

    @Action({ rawError: true })
    async getTable(galleryId: string) {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        let filter = galleryId ? `filters[0][id]=sellerGallery&filters[0][value]=${galleryId}` : '';
        if (!sort && filter.length > 0) {
            filter = `&${filter}`;
        }

        filter += this.filter.filterSettings.filter;
        const result = await getOrdersList(this.pageSettings.pageCurrent, sort, filter, 0);
        await this.setTable(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setTable(table: TableApiInterface) {
        const result = await prepareTable(table, this.statuses);

        this.context.commit('SET_TABLE', result);
    }

    @Action({ rawError: true })
    clearSort() {
        this.context.commit('SET_SORT', {});
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateMultiSelect(params: FilterMultiSelectParamsInterface) {
        await this.filter.updateMultiSelect(params);
        this.filter.updateFilter();
    }
}

export default getModule(GalleryOrdersModule);
