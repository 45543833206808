import { TableApiInterface, TableInterface } from './layouts/page/table.interface';
import { formatCreatedAt, formatPhone } from './utils/Utils';

export function prepareTable(table: TableApiInterface) {
    const hiddenColumns = Object.values(table.titles)
        .filter((title) => title.visible === false)
        .map((title) => title.id);

    const rows: any = [];

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];

            if (i === 'fullName') {
                cell = cell ? cell : 'Имя отсутствует';
                cell = `<span class="cp-table-column__accent">${cell}</span>`;
            }

            if (i == 'phone') {
                cell = cell ? formatPhone(cell) : '';
            }

            if (i === 'lastLogin' && cell) {
                cell = formatCreatedAt(cell);
            }

            rowProcessed[i] = cell;
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: table.titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}
