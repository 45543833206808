
import { Vue, Component } from 'vue-property-decorator';

import GalleryEntity from '@/admin/store/sellers/galleries/entity';
import GalleryTransactions from '@/admin/store/sellers/galleries/transactions';
import GalleryTransactionEntity from '@/admin/store/sellers/galleries/transactionEntity';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';
import TransactionForm from '@/components/Transaction.vue';
import Statistics from '@/components/Statistics.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

@Component({
    components: {
        Loading,
        TableBlock,
        Statistics,
        WarningModal,
        TransactionForm,
    },
})
export default class GalleryBalanceComponent extends Vue {
    isWarningShow = false;
    texts = {
        no: 'Нет',
        yes: 'Да',
        title: 'Внимание',
        description: 'Вы действительно хотите отменить транзакцию?',
    };
    transactionToDelete = 0;
    statisticsUpdated = 0;

    get isWriteAllow(): boolean {
        return RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(UserModule.role as RoleEnum);
    }

    get settings() {
        return GalleryTransactions.pageSettings;
    }

    get isSubmitBlocked() {
        return GalleryTransactionEntity.isSendBlocked;
    }

    get model() {
        return GalleryEntity.model;
    }

    get isTableLoading() {
        return GalleryTransactions.isLoading;
    }

    get transactionModel() {
        return GalleryTransactionEntity.model;
    }

    async selectAmount(items: string) {
        await GalleryTransactions.updateItems(items);
        this.requestTable();
    }

    get paginationAction() {
        return GalleryTransactions.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: GalleryTransactions.pageSettings.pageAmountItems,
        };
    }

    requestTable() {
        if (!this.transactionModel.sellerGallery.current.value) {
            return;
        }

        GalleryTransactions.getTable({
            sellerName: this.transactionModel.sellerGallery.current.value,
        });
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            await GalleryTransactions.updateSortValue(params);
            this.requestTable();
        }

        if (params.action === 'delete') {
            this.transactionToDelete = params.id;
            this.isWarningShow = true;
        }
    }

    async transactionFormHandler(event: { action: string; value: string | InputSuggestionItem }) {
        if (event.action === 'operationType') {
            if (typeof event.value !== 'string') {
                return;
            }

            GalleryTransactionEntity.updateOperationType(event.value);
        }

        if (event.action === 'sum') {
            if (typeof event.value !== 'string') {
                return;
            }

            GalleryTransactionEntity.updateSum(event.value);
        }

        if (event.action === 'comment') {
            if (typeof event.value !== 'string') {
                return;
            }

            GalleryTransactionEntity.updateComment(event.value);
        }
    }

    async removeTransaction() {
        await GalleryTransactionEntity.cancelTransaction(this.transactionToDelete);

        this.transactionToDelete = 0;
        this.isWarningShow = false;

        this.requestTable();
    }

    async submit() {
        const isValidationError = await GalleryTransactionEntity.send();

        if (isValidationError) {
            return;
        }

        this.statisticsUpdated++;

        this.requestTable();
    }

    closeModal() {
        this.isWarningShow = false;
        this.transactionToDelete = 0;
    }

    async created() {
        GalleryTransactions.setDefaultSort(false);

        if (GalleryEntity.galleryName) {
            GalleryTransactionEntity.initSellerInfo({
                id: +this.$route.params.galleryId,
                value: GalleryEntity.galleryName,
            });
            await this.requestTable();

            return;
        }

        await GalleryEntity.initCardById(this.$route.params.galleryId);

        GalleryTransactionEntity.initSellerInfo({
            id: GalleryEntity.cardId as number,
            value: GalleryEntity.galleryName,
        });
        await this.requestTable();
    }

    beforeDestroy() {
        GalleryTransactionEntity.reset();
        GalleryTransactions.resetTable();
    }
}
