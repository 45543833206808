/*
 * filter model factory
 * */

import { FilterSearchInterface as filterSearch } from '@/lib/layouts/page/filterSearch.interface';
import { FilterSelectInterface as filterSelect } from '@/lib/layouts/page/filterSelect.interface';
import { FilterMultiSelectInterface as filterMultiSelect } from '@/lib/layouts/page/filterMultiSelect.interface';
import { FilterCheckboxInterface as filterCheckbox } from '@/lib/layouts/page/filterCheckbox.interface';
import { FilterDatePickerInterface as filterDatePicker } from '@/lib/layouts/page/filterDatePicker.interface';
import { FilterInputSuggestionsInterface as filterSuggestions } from '@/lib/layouts/page/filterSuggestions.interface';
export default class FilterFactory {
    filterModel: any = {};

    createSearchEntity(key: string): void {
        const searchEntity = {
            [key]: {
                key: key,
                placeholder: '',
                name: '',
                class: '',
                previewName: '',
                type: 'text',
                value: '',
                action: 'filterModule/updateSearch',
            } as filterSearch,
        };

        this.filterModel = { ...this.filterModel, ...searchEntity };
    }

    createSelectEntity(key: string): void {
        const selectEntity = {
            [key]: {
                key: key,
                title: '',
                name: '',
                class: '',
                previewName: '',
                type: 'select',
                list: [],
                current: {
                    id: '0',
                    value: '',
                },
                action: 'filterModule/updateSelect',
                initAction: '',
                clearAction: 'filterModule/resetSelectAndUpdate',
                error: {
                    class: '',
                    message: '',
                },
            } as filterSelect,
        };

        this.filterModel = { ...this.filterModel, ...selectEntity };
    }

    createMultiSelectEntity(key: string): void {
        const multiSelectEntity = {
            [key]: {
                key: key,
                title: '',
                name: '',
                class: '',
                previewName: '',
                type: 'multi-select',
                list: [],
                value: '',
                action: 'filterModule/updateMultiSelect',
                clearAction: '',
                error: {
                    class: '',
                    message: '',
                },
            } as filterMultiSelect,
        };

        this.filterModel = { ...this.filterModel, ...multiSelectEntity };
    }

    createCheckboxEntity(key: string): void {
        const checkboxEntity = {
            [key]: {
                key: key,
                title: '',
                name: '',
                class: '',
                previewName: '',
                type: 'checkbox',
                action: 'filterModule/updateCheckbox',
                list: [
                    {
                        id: '',
                        name: '',
                        value: '',
                        checked: false,
                    },
                ],
            } as filterCheckbox,
        };

        this.filterModel = { ...this.filterModel, ...checkboxEntity };
    }

    createRadioEntity(key: string): void {
        const radioEntity = {
            [key]: {
                key: key,
                title: '',
                name: '',
                class: '',
                previewName: '',
                type: 'radio',
                action: 'filterModule/updateRadio',
                value: null,
                list: [
                    {
                        id: '',
                        name: '',
                        value: '',
                        checked: false,
                    },
                ],
            } as filterCheckbox,
        };

        this.filterModel = { ...this.filterModel, ...radioEntity };
    }

    createDateIntervalPickerEntity(key: string): void {
        const datePickerEntity = {
            [key]: {
                key: key,
                name: '',
                class: '',
                previewName: '',
                title: '',
                type: 'date-picker',
                list: {
                    startDate: {
                        key: 'start-date',
                        type: 'date-picker',
                        title: 'С даты',
                        placeholder: '',
                        value: '',
                        action: 'filterModule/updateStartDate',
                    },
                    endDate: {
                        key: 'end-date',
                        type: 'date-picker',
                        title: 'До даты',
                        placeholder: '',
                        value: '',
                        action: 'filterModule/updateEndDate',
                    },
                },
            } as filterDatePicker,
        };

        this.filterModel = { ...this.filterModel, ...datePickerEntity };
    }

    createSuggestionsEntity(key: string): void {
        const inputSuggestionsEntity = {
            [key]: {
                key: key,
                type: 'suggestions',
                title: '',
                name: '',
                placeholder: '',
                value: '',
                current: { id: 0, value: '' },
                hintsList: [],
                size: 0,
                action: '',
                searchAction: '',
                error: {
                    class: '',
                    message: '',
                },
                validation: {
                    empty: '',
                },
                autocomplete: '',
            } as filterSuggestions,
        };

        this.filterModel = { ...this.filterModel, ...inputSuggestionsEntity };
    }

    setPlaceholder(key: string, placeholder: string): void {
        this.filterModel[key]['placeholder'] = placeholder;
    }

    setName(key: string, name: string): void {
        this.filterModel[key]['name'] = name;
    }

    setClassName(key: string, className: string): void {
        this.filterModel[key]['class'] = className;
    }

    setIconClassName(key: string, className: string): void {
        this.filterModel[key]['iconClass'] = className;
    }

    setPreviewName(key: string, previewName: string) {
        this.filterModel[key]['previewName'] = previewName;
    }

    setAction(key: string, action: string): void {
        this.filterModel[key]['action'] = action;
    }

    setInitAction(key: string, action: string): void {
        this.filterModel[key]['initAction'] = action;
    }

    setSearchAction(key: string, action: string): void {
        this.filterModel[key]['searchAction'] = action;
    }

    setClearAction(key: string, action: string): void {
        this.filterModel[key]['clearAction'] = action;
    }

    setTitle(key: string, title: string): void {
        this.filterModel[key]['title'] = title;
    }

    setListElement(key: string, list: {}) {
        this.filterModel[key]['list'][0] = list;
    }

    setList(key: string, list: Array<any>) {
        this.filterModel[key]['list'] = list;
    }

    setDateIntervalList(key: string, list: any) {
        this.filterModel[key]['list'] = list;
    }

    setIgnoreNull(key: string, bool: boolean) {
        this.filterModel[key]['ignoreNull'] = bool;
    }
}
