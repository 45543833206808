
import { Vue, Component, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import InputBlock from './input/InputMain.vue';
import SelectCustom from './select/SelectCustom.vue';
import RadioBtnGroup from '@/components/form/RadioBtn.vue';
import TextareaBlock from './form/Textarea.vue';
import InputSuggestions from './input/InputSuggestions.vue';

import { Select, SelectItem } from '@/lib/formFactory/select.interface';
import { InputSuggestion, InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputEntity from '@/lib/formFactory/inputEntity';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';

@Component({
    components: {
        Loading,
        BtnSubmit,
        InputBlock,
        SelectCustom,
        RadioBtnGroup,
        TextareaBlock,
        InputSuggestions,
    },
})
export default class TransactionFormComponent extends Vue {
    @Prop({ required: false }) readonly isLoading!: boolean;
    @Prop({ required: true }) readonly operationType!: RadioEntity;
    @Prop({ required: true }) readonly comment!: InputEntity;
    @Prop({ required: false }) readonly order!: InputSuggestion;
    @Prop({ required: false }) readonly details!: { [key: string]: { title: string; value: string } };
    @Prop({ required: false }) readonly isDetailsVisible!: boolean;
    @Prop({ required: true }) readonly sum!: InputEntity;
    @Prop({ required: false }) readonly customer!: InputSuggestion;
    @Prop({ required: false }) readonly sellerLink!: {
        id: number;
        paramName: string;
        name: string;
        type: string;
    };
    @Prop({ required: false }) readonly sellerType!: Select;
    @Prop({ required: false }) readonly seller!: InputSuggestion;
    @Prop({ required: false }) readonly catalog!: InputSuggestion;
    @Prop({ required: false }) readonly isSubmitBlocked!: boolean;

    get orderIsHidden() {
        if (this.customer && !(this.customer?.current as InputSuggestionItem)?.id) {
            return true;
        }

        return false;
    }

    get isGalleryOrPainter() {
        return (
            this.sellerType &&
            (this.sellerType.current.id === 'sellerGallery' || this.sellerType.current.id === 'sellerPainter')
        );
    }

    getNumberFormatted(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateOperationType(type: string) {
        this.$emit('click', { action: 'operationType', value: type });
    }

    updateSum(sum: string) {
        this.$emit('click', { action: 'sum', value: formatRemoveSpaces(sum) });
    }

    updateSearchCustomer(search: string) {
        this.$emit('click', { action: 'searchCustomer', value: search });
    }

    updateSelectedCustomer(selected: SelectItem) {
        this.$emit('click', { action: 'selectCustomer', value: selected });
    }

    updateComment(comment: string) {
        this.$emit('click', { action: 'comment', value: comment });
    }

    updateSearchOrder(search: string) {
        this.$emit('click', { action: 'searchOrder', value: formatRemoveSpaces(search) });
    }

    updateSelectedOrder(selected: SelectItem) {
        this.$emit('click', { action: 'selectOrder', value: selected });
    }

    updateSearchCatalog(search: string) {
        this.$emit('click', { action: 'searchCatalog', value: search });
    }

    updateSelectedCatalog(selected: InputSuggestionItem) {
        this.$emit('click', { action: 'selectCatalog', value: selected });
    }

    submit() {
        this.$emit('submit');
    }
}
