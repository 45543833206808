import { Vue, Component, Prop } from 'vue-property-decorator';
import { Radio } from '@/lib/formFactory/radio.interface';
import RadioEntity from '@/lib/formFactory/radioEntity';

import RadioGroup from '@/components/form/RadioBtnIcons.vue';
import InputDatePickerEntity from '@/lib/formFactory/inputDatePickerEntity';
import DatePicker from '@/components/form/DatePicker.vue';

import moment from 'moment';
import { InputDatePicker } from '@/lib/formFactory/inputDatePicker.interface';
import { isDateStartOverDateEnd } from '@/lib/utils/Utils';

@Component({
    components: {
        RadioGroup,
        DatePicker,
    },
})
export default class StatisticsMixin extends Vue {
    @Prop({ required: true }) readonly type!: string;
    @Prop({ required: true }) readonly entityId!: string;

    fromDate: InputDatePicker | null = null;
    toDate: InputDatePicker | null = null;

    timeRange: Radio | null = null;
    timeRangeFilterType = 'all';
    filterString = '';

    initStatistics(): void {
        console.info('initStatistics');
    }

    async getStatistics(): Promise<void> {
        console.info('getStatistics');
    }

    updateFilter(): void {
        let filter = '';
        let start = '';
        let end = '';

        if (this.timeRangeFilterType === 'quarter') {
            ({ start, end } = this.getStartEndOfQuarter());
            filter = `?fromDate=${start}&toDate=${end}`;
        }

        if (this.timeRangeFilterType === 'year') {
            start = moment().utcOffset(0, true).startOf('year').format();
            end = moment().utcOffset(0, true).endOf('year').format();
            filter = `?fromDate=${start}&toDate=${end}`;
        }

        this.filterString = filter;
        this.getStatistics();
    }

    getStartEndOfQuarter(): { start: string; end: string } {
        const quarter = moment().utcOffset(0, true).quarter();
        const start = moment().utcOffset(0, true).quarter(quarter).startOf('quarter').format();
        const end = moment().utcOffset(0, true).quarter(quarter).endOf('quarter').format();

        return { start, end };
    }

    handleFromDate(params: { day: string; month: string; year: string }): void {
        if (!this.fromDate) {
            return;
        }

        this.fromDate.value = `${params.day}/${params.month}/${params.year}`;
        this.handleDate();
    }

    handleToDate(params: { day: string; month: string; year: string }): void {
        if (!this.toDate) {
            return;
        }

        this.toDate.value = `${params.day}/${params.month}/${params.year}`;
        this.handleDate();
    }

    handleDate(): void {
        let filter = '';
        let fromDate = '';
        let toDate = '';

        if (
            this.fromDate?.value &&
            this.toDate?.value &&
            isDateStartOverDateEnd(this.fromDate.value, this.toDate?.value)
        ) {
            (this.toDate as InputDatePicker).value = (this.fromDate as InputDatePicker).value;
        }

        if (this.fromDate?.value) {
            fromDate = moment(`${this.fromDate.value}`, 'DD-MM-YYYY').utcOffset(0, true).format();
        }

        if (this.toDate?.value) {
            toDate = moment(`${this.toDate.value}`, 'DD-MM-YYYY').utcOffset(0, true).format();
        }

        if (fromDate) {
            filter += `?fromDate=${fromDate}`;
        }

        if (filter.length && toDate) {
            filter += `&toDate=${toDate}`;
        }

        if (!filter.length && toDate) {
            filter += `?toDate=${toDate}`;
        }

        this.filterString = filter;
        this.getStatistics();
    }

    updateTimeRange(id: number): void {
        if (!this.timeRange?.list[id]?.value) {
            return;
        }

        this.timeRangeFilterType = String(this.timeRange.list[id].value);

        this.updateFilter();
    }

    created(): void {
        this.initStatistics();

        const timeRangeEntity = new RadioEntity('range');
        timeRangeEntity.setList([
            { id: 0, name: 'Всего', value: 'all' },
            { id: 1, name: 'Этот квартал', value: 'quarter' },
            { id: 2, name: 'Этот год', value: 'year' },
            { id: 3, name: 'Укажите период', value: 'custom' },
        ]);
        timeRangeEntity.setValue('all');

        this.timeRange = timeRangeEntity.entity[timeRangeEntity.entityName];

        const fromDateEntity = new InputDatePickerEntity('fromData');
        fromDateEntity.setTitle('От');

        const toDateEntity = new InputDatePickerEntity('toData');
        toDateEntity.setTitle('До');

        this.fromDate = fromDateEntity.entity[fromDateEntity.entityName];
        this.toDate = toDateEntity.entity[toDateEntity.entityName];
    }
}
