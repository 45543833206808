/**
 * Page base module.
 *
 */

import { VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { PageInterface, PageSort, SortItem } from '@/lib/layouts/page/page.interface';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getApiErrorMessage, showError } from '@/lib/utils/Utils';

export default abstract class PageBaseModule extends VuexModule {
    pageSettings: PageInterface;
    isGlobalError = false;
    isLoading = true;

    constructor(module: PageBaseModule) {
        super(module);
        const page = new PageEntity();
        this.pageSettings = page.values;
    }

    @Mutation
    SET_TABLE(table: TableInterface) {
        this.pageSettings.table = table;
    }

    @Mutation
    RESET_TABLE() {
        this.pageSettings.table = {
            titles: [],
            rows: [],
            sortFields: {},
            pagination: null,
            totalItems: 0,
            filters: {},
            hiddenColumns: {},
        };
    }

    @Mutation
    SET_PAGE(number: number) {
        this.pageSettings.pageCurrent = number;
    }

    @Mutation
    SET_PAGE_AMOUNT_ITEMS(number: string) {
        this.pageSettings.pageAmountItems = number;
    }

    @Mutation
    setGlobalError(isGlobalError: boolean) {
        this.isGlobalError = isGlobalError;
    }

    @Mutation
    SET_IS_LOADING(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @Mutation
    SET_SORT(sorts: PageSort) {
        this.pageSettings.sort = sorts;

        if (this.pageSettings.sortName) {
            window.localStorage[this.pageSettings.sortName] = JSON.stringify(sorts);
        }
    }

    @Mutation
    SET_FILTER_REQUEST_SORT(sort: string) {
        this.pageSettings.filterRequestSort = sort;
    }

    // @Action({ rawError: true })
    // setFilterModel(filterModel: {}) {
    //     FilterModule.setFilterModel(filterModel);
    // }

    // @Action({ rawError: true })
    // setFilterName(filterName: string) {
    //     FilterModule.setFilterName(filterName);
    // }

    // @Action({ rawError: true })
    // setFilterHandlers(filterHandlers: {}) {
    //     FilterModule.setFilterHandlers(filterHandlers);
    // }

    // @Action({ rawError: true })
    // async initFilter() {
    //     FilterModule.init();
    // }

    @Action({ rawError: true })
    initList(): void {
        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    getList(): void {
        this.context.dispatch('setList', {} as TableApiInterface);
    }

    @Action({ rawError: true })
    getSortForRequest() {
        let sort = '';
        let index: string;
        let item: SortItem;
        for ([index, item] of Object.entries(this.pageSettings.sort)) {
            if (!sort.length) {
                sort = '&';
            }

            sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
        }

        return sort;
    }

    @Action({ rawError: true })
    setDefaultSort(asc = true) {
        const defaultSortField = this.pageSettings.defaultSortField ?? '';

        if (!defaultSortField) {
            return;
        }

        this.context.commit('SET_SORT', { 0: { key: defaultSortField, sort: asc ? 'ASC' : 'DESC' } });
    }

    @Action({ rawError: true })
    initListById(id: string): void {
        this.getListById(id);
    }

    @Action({ rawError: true })
    getListById(id: string): void {
        console.info(id);
    }

    @Action({ rawError: true })
    setList(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => {
                return title.id;
            });
        const tableInfo = {
            titles: table.titles,
            rows: table.rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;

        this.context.commit('SET_TABLE', tableInfo);
    }

    @Action({ rawError: true })
    updateTitleEdit(title: string) {
        this.pageSettings.titleEdit = title;
    }

    @Action({ rawError: true })
    updateIsLoading(isLoading: boolean) {
        this.context.commit('SET_IS_LOADING', isLoading);
    }

    @Action({ rawError: true })
    updatePage(number: string) {
        this.context.commit('SET_PAGE', parseInt(number));
        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    updateItems(number: string | number) {
        this.context.commit('SET_PAGE_AMOUNT_ITEMS', number.toString);
        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    changePage(number: string) {
        this.context.commit('SET_PAGE', parseInt(number));
    }

    @Action({ rawError: true })
    async updatePageAmountItems(number: string) {
        await this.context.commit('SET_PAGE', 1);
        await this.context.commit('SET_PAGE_AMOUNT_ITEMS', number);
        await this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    async updatePaginationSettings(params: { moduleName: string; amountItems: string }) {
        await this.context.dispatch('setPageAmountStorageValue', {
            moduleName: params.moduleName,
            number: params.amountItems,
        });
        await this.context.commit('SET_PAGE', 1);
        await this.context.commit('SET_PAGE_AMOUNT_ITEMS', params.amountItems);
    }

    @Action({ rawError: true })
    setPageAmountStorageValue(params: { moduleName: string; number: string }) {
        window.localStorage[`${params.moduleName}_pageAmountItems`] = params.number;
    }

    @Action({ rawError: true })
    getPageAmountStorageValue(moduleName: string) {
        try {
            return window.localStorage[`${moduleName}_pageAmountItems`];
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async getItemsQuery(moduleName: string) {
        try {
            const pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', moduleName);

            return pageAmountItems ? `items=${pageAmountItems}&` : '';
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async sort(params: { field: string; sort: string }) {
        const sortProcessed = await this.context.dispatch('sortProcessed', params);

        this.context.commit('SET_SORT', sortProcessed);
        this.context.dispatch('getList');
    }

    @Action({ rawError: true })
    sortProcessed(params: { field: string; sort: string }) {
        let sortNew = '';
        if (!params.sort) {
            sortNew = 'ASC';
        }

        if (params.sort === 'ASC') {
            sortNew = 'DESC';
        }

        if (params.sort === 'DESC') {
            sortNew = '';
        }

        if (!sortNew.length) {
            return [];
        }

        return [{ key: params.field, sort: sortNew }];
    }

    @Action({ rawError: true })
    clearSort() {
        this.context.commit('SET_SORT', {});
    }

    @Action({ rawError: true })
    resetFilterState() {
        console.debug('resetFilterState');
    }

    @Action({ rawError: true })
    reset(): void {
        this.context.commit('SET_TABLE', {});
        this.context.commit('SET_PAGE', 1);
        this.context.commit('SET_IS_LOADING', false);
    }
}
