/*
 * page entity for state
 * */

import { PageInterface } from '@/lib/layouts/page/page.interface';
export default class PageEntity {
    values: PageInterface;

    constructor() {
        this.values = {
            title: '',
            titleAdd: '',
            titleEdit: '',
            table: {},
            pageCurrent: 1,
            pageAmountItems: '25',
            sort: {},
            filter: '',
            filterAdditional: '',
            filterPreview: '',
            filterModel: {},
            filterValues: {},
            filterHandlers: {},
        };
    }

    setTitle(title: string) {
        this.values.title = title;
    }

    setTitleAdd(title: string) {
        this.values.titleAdd = title;
    }

    setTitleEdit(title: string) {
        this.values.titleEdit = title;
    }

    setDefaultSortField(field: string) {
        this.values.defaultSortField = field;
    }

    setSortName(name: string) {
        this.values.sortName = name;
    }
}
