
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Textarea extends Vue {
    @Prop() data!: Record<string, any>;
    @Prop() disabled?: false;
    @Prop() entityId?: string | number;
    @Prop() rows!: number | string;

    isLabelActive = false;

    get valueModel(): string {
        return this.data.value;
    }

    set valueModel(value: string) {
        if (this.entityId) {
            this.$emit('input', { value, entityId: this.entityId });

            return;
        }

        this.$emit('input', value);
    }

    onFocus(): void {
        this.isLabelActive = true;
    }

    onFocusout(): void {
        this.isLabelActive = false;
        if (this.data.errorHandler) {
            this.$store.dispatch(this.data.errorHandler, { index: this.data.key, field: this.data }, { root: true });
        }
    }
}
