<template>
    <div class="outer-addition cp-flex align-center gap-5 full-width">
        <slot />
        <span class="cp-text-info">{{ additional }}</span>
    </div>
</template>

<script>
export default {
    name: 'OuterAddition',
    props: {
        additional: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped></style>
