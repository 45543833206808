<template>
    <div class="mr-title__wrapper mr-hide-mobile">
        <div class="cp-arrow-return__wrapper">
            <div class="cp-title-page__wrapper">
                <icon-font v-if="icon" class="cp-title-page__icon" :size="iconSize" :icon="icon" />

                <h1 class="cp-title-page">
                    <slot></slot>
                </h1>
            </div>

            <div class="cp-title__actions">
                <div v-if="totalItems" class="cd-admin-table-total">Всего: {{ totalItems }}</div>

                <div v-if="isAdd" class="cp-flex" @click="toAdd">
                    <icon-font size="20" icon="add" />
                </div>

                <a v-if="link || showLink" :href="link" target="_blank" class="cd-btn-primary cd-btn-radius-50"
                    >Показать на сайте</a
                >

                <div v-if="!isReturnHidden" class="cp-arrow-return cp-margin__l--s" @click="returnBefore">
                    <arrow-return color="#9B9BC7" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconFont from '@/components/icons/IconFont.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';

export default {
    components: {
        IconFont,
        ArrowReturn,
    },
    props: {
        iconSize: {
            type: String,
        },
        icon: {
            type: String,
        },
        isAdd: {
            type: Boolean,
        },
        isReturnHidden: {
            type: Boolean,
        },
        totalItems: {
            type: Number,
        },
        link: {
            type: String,
        },
        showLink: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        returnBefore() {
            this.$emit('click');
        },

        toAdd() {
            this.$emit('to-add');
        },
    },
};
</script>
