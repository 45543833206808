
import { Mixins, Component, Prop } from 'vue-property-decorator';
import EditorModal from './_editorModal.vue';
import BtnLocalization from '@/components/buttons/BtnLocalization.vue';
import InputLocalizationMixin from '@/components/input/InputLocalizationMixin';
import { Localization, LocalizationItem } from '@/interfaces/localization';
import { Input } from '@/lib/formFactory/input.interface';

export interface EditorPropLocalization {
    title: string;
    value: string;
    key: string;
    error: {
        class: string;
        message: string;
    };
    localization: Localization;
}

@Component({
    components: {
        EditorModal,
        BtnLocalization,
    },
})
export default class EditorModalLocalization extends Mixins(InputLocalizationMixin) {
    @Prop({ default: '' }) title!: string;
    @Prop({ required: true }) data!: Input;
    @Prop() editor!: EditorPropLocalization;

    componentCounter = 0;
    localizationLocal = this.data.localization ?? {};

    get currentValue(): string {
        if (this.localizationLocal && this.languageModel in this.localizationLocal) {
            return (this.localizationLocal as LocalizationItem)[this.languageModel];
        }

        return '';
    }

    set currentValue(value: string) {
        (this.localizationLocal as LocalizationItem)[this.languageModel] = value;
    }

    onInput(value: string): void {
        this.currentValue = value;
    }

    switchLanguage(): void {
        const currLangIndex = this.languages.findIndex((lang) => lang === this.languageModel);
        const nextLanguage = this.languages[currLangIndex + 1] || this.languages[0];

        this.languageModel = nextLanguage;
        this.componentCounter++;
    }

    async save(): Promise<void> {
        this.$emit('save', { ...this.localizationLocal });
        this.closeModal();
    }

    closeModal(): void {
        this.$emit('click');
    }
}
