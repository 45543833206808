
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class ButtonDefaultComponent extends Vue {
    @Prop({ required: false, default: '' }) classAdd!: string;
    @Prop({ required: false, default: '' }) handler!: string;

    action(): void {
        this.$emit('click');
    }
}
