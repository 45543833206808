import { makeDeleteRequest, makeFormRequest, makeGetRequest, makePostRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';

/**
 * Get orders list.
 *
 * @param currentPage
 * @param filter
 * @param sort
 * @param items
 */
export const getOrdersList = async (
    currentPage: string | number,
    sort: string,
    filter: string,
    items: number | string,
) => {
    filter = filter || '';
    sort = sort ? `&${sort}` : '';
    const itemsFilter = items ? `&items=${items}` : '';
    const result = await makeGetRequest(API.orders.getList + `?page=${currentPage}${itemsFilter}${sort}${filter}`);

    return result.data.result.table;
};

/**
 * Get order card.
 *
 * @param cardId
 */
export const getOrderCard = async (cardId: string | number) => {
    const result = await makeGetRequest(API.orders.card + `${cardId}/`);

    return result.data.result.item;
};

/**
 * Edit order card.
 *
 * @param cardId
 * @param data
 */
export const editOrderCard = async (cardId: number, data: any) => {
    const result = await makeFormRequest(API.orders.card + `${cardId}/`, data);

    return result.data.result.item;
};

/**
 * Remove order card.
 *
 * @param cardId
 */
export const deleteOrder = async (orderId: number) => {
    const result = await makeDeleteRequest(API.orders.card + `${orderId}/`);

    return result;
};

/**
 * Get order statuses.
 *
 */
export const getOrdersStatuses = async () => {
    const result = await makeGetRequest(API.orders.statuses);

    return result.data.result.table;
};

/**
 * Remove order card.
 *
 * @param orderId
 * @param lotId
 */
export const deleteLotFromOrder = async (orderId: number, lotId: number) => {
    const result = await makeDeleteRequest(API.orders.card + `${orderId}/lot/${lotId}`);

    return result.data.result.item;
};

/**
 * Add lot to order
 *
 * @param orderId
 * @param lotId
 */
export const addLotToOrder = async (orderId: number, lotId: number) => {
    const result = await makePostRequest(API.orders.card + `${orderId}/lot/${lotId}`);

    return result.data.result.item;
};
