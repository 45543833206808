import { YesNoMap } from '@/constants';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { formatCreatedAt } from '@/lib/utils/Utils';

const booleanValueFields = ['isPublished', 'isActive', 'isHideImage', 'isHidePrice'];

/**
 * Returns processed table info
 *
 * @param {Object} table shops data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareTable(
    table: TableApiInterface,
    statuses: { id: string; name: string }[],
    hideNumberTitle = true,
): TableInterface {
    table.titles.push(
        { id: '_actions', name: 'check', visible: true, value: false },
        { id: 'checked', name: 'checked', visible: false },
    );
    let hiddenColumns = Object.values(table.titles)
        .filter((title) => title.visible === false || (title.id === 'number' && hideNumberTitle))
        .map((title) => title.id);
    hiddenColumns = [...hiddenColumns, ...['sellerId']];

    const rows: any = [];

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];
            const sellerType = row['sellerType'];
            const lotId = row['id'];

            if (i === 'name') {
                cell = lotId
                    ? `<a href="${window.location.origin}/auctions/lot/${sellerType}/${lotId}" target="_blank" class="cp-table__item-link">
                            <span class="cp-table-column__accent">${cell || lotId}</span>
                        </a>`
                    : cell;
            }

            if (i === 'sellerName') {
                cell = lotId
                    ? `<a href="${window.location.origin}/sellers/${sellerType}/${row['sellerId']}" target="_blank" class="cp-table__item-link">
                            <span class="cp-table-column__bold">${cell}</span>
                        </a>`
                    : cell;
            }

            if (i === 'status') {
                const status = statuses.find((status) => status.id === cell)?.name;
                cell = status ?? cell;
            }

            if (i === 'dateTimeStartTrad') {
                cell = cell ? formatCreatedAt(cell) : '-';
            }

            if (i === 'datetimePosting') {
                cell = cell ? formatCreatedAt(cell) : '-';
            }

            if (i === '_actions') {
                rowProcessed['checked'] = false;
                cell = ['checkbox'];
            }

            if (booleanValueFields.includes(i)) {
                cell = YesNoMap.get(cell);
            }

            rowProcessed[i] = cell;
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: table.titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}
