import { render, staticRenderFns } from "./TitleReturn.vue?vue&type=template&id=23512931&"
import script from "./TitleReturn.vue?vue&type=script&lang=js&"
export * from "./TitleReturn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports