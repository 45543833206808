
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import GalleryOrders from '@/admin/store/sellers/galleries/orders';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/TableLots.vue';

@Component({
    components: {
        FlexLayout,
        AppHeaderMain,
        Loading,
        TableBlock,
    },
})
export default class GalleryOrdersComponent extends Vue {
    get isLoading() {
        return GalleryOrders.isLoading;
    }

    get settings() {
        return GalleryOrders.pageSettings;
    }

    get filterSettings() {
        return GalleryOrders.filter.filterSettings;
    }

    get galleryId() {
        return this.$route.params.galleryId;
    }

    updateList() {
        GalleryOrders.getTable(this.galleryId);
    }

    async resetFilters() {
        await GalleryOrders.clearSort();
        GalleryOrders.getTable(this.galleryId);
    }

    created() {
        GalleryOrders.init(this.galleryId);
    }

    beforeDestroy(): void {
        GalleryOrders.reset();
    }
}
