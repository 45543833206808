/**
 * Transactions table page base module
 *
 */

import { Action, Mutation } from 'vuex-module-decorators';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';

import { getTransactionOperationNames, getTransactionsList } from '@/admin/api/transaction';
import { prepareTableTransactions } from '@/lib/Finance';
import { getOrdersStatuses } from '../api/orders';

export default abstract class FinanceOrdersTableModule extends PageBaseModule {
    params: { sellerName?: string; catalogName?: string; orderId?: string | number } = {};
    operationNames: { id: string; name: string }[] = [];
    statuses: { id: string; name: string }[] = [];

    constructor(module: FinanceOrdersTableModule) {
        super(module);

        this.isLoading = false;

        const page = new PageEntity();
        this.pageSettings = page.values;
    }

    @Mutation
    SET_REQUEST_PARAMS(params: { sellerName?: string; catalogName?: string; orderId?: string | number }) {
        this.params = params;
    }

    @Mutation
    SET_PAGE_AMOUNT_ITEMS(number: string | number) {
        this.pageSettings.pageAmountItems = number.toString();
    }

    @Mutation
    SET_OPERATION_NAMES(list: { id: string; name: string }[]) {
        this.operationNames = list;
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Action({ rawError: true })
    async updateSortValue(params: { field: string; sort: string }) {
        const sortProcessed = await this.context.dispatch('sortProcessed', params);

        this.context.commit('SET_SORT', sortProcessed);
    }

    @Action({ rawError: true })
    updateItems(number: string | number) {
        this.context.commit('SET_PAGE_AMOUNT_ITEMS', number);
    }

    @Action({ rawError: true })
    updatePage(number: string) {
        this.context.commit('SET_PAGE', parseInt(number));
        this.context.dispatch('getTable', this.params);
    }

    @Action({ rawError: true })
    async initOperationNames() {
        this.context.commit('SET_OPERATION_NAMES', await getTransactionOperationNames());
    }

    @Action({ rawError: true })
    async initOrderStatuses() {
        this.context.commit('SET_STATUSES', await getOrdersStatuses());
    }

    @Action({ rawError: true })
    async getTable(params: { customerId?: number; sellerName?: string; catalogName?: string }) {
        this.context.commit('SET_IS_LOADING', true);

        let filter = params.customerId ? `&filters[0][id]=customer&filters[0][value]=${params.customerId}` : '';
        filter += params.sellerName ? `&filters[1][id]=seller&filters[1][value]=${params.sellerName}` : '';
        filter += params.catalogName ? `&filters[2][id]=auction&filters[2][value]=${params.catalogName}` : '';

        const result = await getTransactionsList(
            this.pageSettings.pageCurrent,
            '',
            filter,
            +this.pageSettings.pageAmountItems,
        );

        await this.context.dispatch('setInfo', result);
        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    async setInfo(table: TableApiInterface) {
        const tableProcessed = await prepareTableTransactions(table, this.operationNames, this.statuses);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    resetTable() {
        this.context.commit('RESET_TABLE');
    }
}
