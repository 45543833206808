import { TableApiInterface, TableInterface } from './layouts/page/table.interface';
import { formatNumberSpaced, truncateString } from './utils/Utils';
import UserModule from '@/admin/store/user';

const isRoleSuperAdmin = UserModule.role === 'ROLE_SUPER_ADMIN';

export function prepareTableTransactions(
    table: TableApiInterface,
    operationNames: { id: string; name: string }[],
    statuses: { id: string; name: string }[],
): TableInterface {
    const titles = [...table.titles, { id: '_actions', name: '', visible: true }];
    const hiddenColumns: string[] = Object.values(table.titles).reduce((result: string[], title) => {
        if (!title.visible) {
            result = [...result, title.id];
        }

        return result;
    }, []);

    const rows: any = [];

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];

            if (i === 'operationName') {
                const match = operationNames.filter((name) => name.id === cell)[0];
                cell = match ? match.name : cell;
            }

            if (i === 'sum' && row['sum'] && row['operationType'] === 'pay') {
                cell = `- ${formatNumberSpaced(row['sum'])}`;
            }

            if (i === 'sum' && row['sum'] && row['operationType'] === 'receipt') {
                cell = `+ ${formatNumberSpaced(row['sum'])}`;
            }

            if (i === '_actions' && row['canceled'] === false && row['manual'] === true && isRoleSuperAdmin) {
                cell = ['delete'];
            }

            if (i === 'order' && cell) {
                let bgColor = 'cp-bg-default';
                bgColor = cell === 'not_paid' ? 'cp-bg-red cp-color-white' : cell;
                bgColor = ['paid_through_site', 'paid_in_another', 'paid_directly_to_seller'].includes(cell.status)
                    ? 'cp-bg-green cp-color-white'
                    : bgColor;

                cell = `
                    <a href="${window.location.origin}/auctions/order/${
                    cell.id
                }" target="_blank" class="cp-table__item-link">
                        ${formatNumberSpaced(cell.id)}
                    </a>
                    <div class="${bgColor} cp-padding__table-label">${
                    statuses.filter((status) => status.id === cell.status)[0]?.name
                }</div>
                    `;
            }

            if (i === 'seller' && cell) {
                cell =
                    cell.sellerType && cell.id
                        ? `<a 
                            href="${window.location.origin}/sellers/${cell.sellerType}/${cell.id}" 
                            target="_blank"
                            class="cp-table__item-link">${cell.name}
                        </a>
                        <div>${cell.sellerTypeName}</div>`
                        : `<div>${cell.name}</div>
                        <div>${cell.sellerTypeName}</div>`;
            }

            if (i === 'auction' && cell) {
                cell = `<a href="${window.location.origin}/auctions/auction/${cell.id}" target="_blank" title="${
                    cell.name
                }">
                            ${truncateString(cell.name, 30)}
                        </a>`;
            }

            rowProcessed[i] = cell;
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}
