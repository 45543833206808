
import { Component, Vue, Prop } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';

import SiteModule from '@/admin/store/site';

@Component({
    components: {
        Loading,
    },
})
export default class BtnSubmit extends Vue {
    @Prop({ required: true }) readonly classAdd?: string;

    get isLoading(): boolean {
        return SiteModule.isBlock;
    }

    get classes(): string {
        const classIsLoading = this.isLoading === true ? 'loading' : '';

        return `${classIsLoading} ${this.classAdd}`;
    }

    action(event: Event): void {
        if (this.isLoading) {
            event.preventDefault();

            return;
        }

        this.$emit('click');
    }
}
