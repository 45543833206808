/**
 * filter entity for state
 */

import { Mutation, Action, VuexModule } from 'vuex-module-decorators';
import { Filter, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { isDateStartOverDateEnd } from '@/lib/utils/Utils';
import moment from 'moment';
import { MultiSelectItem } from '@/interfaces/multiSelect.interface';
import { InputSuggestionItem, InputSuggestionList } from '@/lib/formFactory/inputSuggestion.interface';
import { FilterInputSuggestionsParamsInterface } from '@/lib/layouts/page/filterParams.interface';

export const MODULE_NAME = 'filterModule';

export default abstract class FilterModule extends VuexModule implements Filter {
    self = this;
    filterSettings: FilterSettings;
    index: number;

    constructor(module: FilterModule) {
        super(module);
        this.filterSettings = {
            name: '',
            getListAction: '',
            pageCurrent: 1,
            sort: {},
            filter: '',
            filterAdditional: '',
            filterPreview: {},
            filterModel: {},
            filterValues: {},
            filterHandlers: {
                update: '',
                reset: '',
                resetState: '',
                removeSelected: '',
            },
            templateClass: '',
            btnClass: 'col-1-row-5',
        };
        this.index = 2;
    }

    @Mutation
    SET_FILTER_NAME(name: string) {
        this.filterSettings.name = name;
    }

    @Mutation
    SET_GET_LIST_ACTION(action: string) {
        this.filterSettings.getListAction = action;
    }

    @Mutation
    SET_TEMPLATE_CLASS_NAME(className: string | string[]) {
        this.filterSettings.templateClass = className;
    }

    @Mutation
    SET_BTN_CLASS_NAME(className: string) {
        this.filterSettings.btnClass = className;
    }

    @Mutation
    SET_FILTER(filter: string) {
        this.filterSettings.filter = filter;
        window.localStorage[`${this.filterSettings.name}String`] = JSON.stringify(filter);
    }

    @Mutation
    SET_FILTER_PREVIEW(filterPreview: any) {
        this.filterSettings.filterPreview = filterPreview;
        window.localStorage[`${this.filterSettings.name}Preview`] = JSON.stringify(filterPreview);
    }

    @Mutation
    SET_MODEL_PREVIEW_NAME(params: { key: string; previewName: string }) {
        this.filterSettings.filterModel[params.key].previewName = params.previewName;
    }

    @Mutation
    DEL_FILTER_PREVIEW(filterName: string) {
        delete this.filterSettings.filterPreview[filterName];
        window.localStorage[`${this.filterSettings.name}Preview`] = JSON.stringify(this.filterSettings.filterPreview);
    }

    @Mutation
    SET_PAGE(number: number) {
        this.filterSettings.pageCurrent = number;
    }

    @Mutation
    SET_FILTER_MODEL(filterModel: {}) {
        this.filterSettings.filterModel = filterModel;
    }

    @Mutation
    SET_FILTER_HANDLERS(filterHandlers: { update: string; reset: string; resetState: string; removeSelected: string }) {
        this.filterSettings.filterHandlers = filterHandlers;
    }

    @Mutation
    SET_FILTER_INDEX(index: number) {
        this.index = index;
    }

    @Mutation
    SET_SEARCH(params: { key: string; value: string }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].value = params.value;
    }

    @Mutation
    SET_SELECT_LIST(params: { key: string; list: any }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].list = params.list;
    }

    @Mutation
    SET_SELECT(params: { key: string; value: { id: number | string; value: string } }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].current = params.value;
    }

    @Mutation
    SET_MULTI_SELECT_LIST(params: { key: string; list: any }) {
        this.filterSettings.filterModel[params.key].list = params.list;

        const list: { id: number; name: string; checked: boolean }[] = this.filterSettings.filterModel[params.key].list;
        const currentValues: string = list
            .filter((item) => item.checked)
            .map((item) => item.name)
            .join(', ');

        this.filterSettings.filterModel[params.key].value = currentValues;
    }

    @Mutation
    SET_MULTI_SELECT(params: { key: string; value: { id: number; value: string } }) {
        const currentValuesArray: string[] = [];
        const list = this.filterSettings.filterModel[params.key].list;
        const newValues = list.map((item: { id: number; name: string; checked: boolean }) => {
            if (item.id === params.value.id) {
                item.checked = !item.checked;
            }
            if (item.checked === true) {
                currentValuesArray.push(item.name);
            }

            return item;
        });

        const currentValues = currentValuesArray.join(', ');

        this.filterSettings.filterModel[params.key].value = currentValues;
        this.filterSettings.filterModel[params.key].list = newValues;
    }

    @Mutation
    RESET_MULTI_SELECT(key: string) {
        if (!this.filterSettings.filterModel[key]) {
            return;
        }
        this.filterSettings.filterModel[key].value = '';
        this.filterSettings.filterModel[key].list.forEach((item: MultiSelectItem) => {
            item.checked = false;
        });
    }

    @Mutation
    SET_CHECKBOX_LIST(params: { key: string; list: any[] }) {
        this.filterSettings.filterModel[params.key].list = params.list;
    }

    @Mutation
    SET_CHECKBOX(params: { key: string; checkbox: any }) {
        this.filterSettings.filterModel[params.key].list.forEach(
            (item: { id: number; name: string; checked: boolean }) => {
                if (item.id == params.checkbox.id) {
                    item.checked = params.checkbox.bool;
                }

                return item;
            },
        );
    }

    @Mutation
    RESET_CHECKBOX(key: string) {
        this.filterSettings.filterModel[key].list.forEach((item: { id: number; name: string; checked: boolean }) => {
            item.checked = false;

            return item;
        });
    }

    @Mutation
    SET_RADIO(params: { key: string; value: number }) {
        if (params.value === 1) {
            this.filterSettings.filterModel[params.key].list.forEach(
                (item: { id: number; name: string; checked: boolean }) => {
                    item.checked = false;
                },
            );
            this.filterSettings.filterModel[params.key].value = null;

            return;
        }

        const dict: { [key: number]: boolean | null } = {
            0: true,
            1: null,
            2: false,
        };

        this.filterSettings.filterModel[params.key].list.forEach(
            (item: { id: number; name: string; checked: boolean; value?: string }) => {
                if (item.id === params.value) {
                    item.checked = true;
                    this.filterSettings.filterModel[params.key].value = item.value ?? dict[item.id];

                    return;
                }
                item.checked = false;
            },
        );
    }

    @Mutation
    SET_RADIO_ICON_CLASS(params: { key: string; className: string }) {
        this.filterSettings.filterModel[params.key].iconClass = params.className;
    }

    @Mutation
    SET_FILTER_DATE(params: { key: string; dateKey: string; date: string }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].list[params.dateKey].value = params.date;
    }

    @Mutation
    SET_SUGGESTIONS_SELECTED(params: { key: string; value: InputSuggestionItem }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].current = params.value;
    }

    @Mutation
    SET_SUGGESTIONS_VALUE(params: { key: string; value: string }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].value = params.value;
    }

    @Mutation
    SET_SUGGESTIONS_HINTS_LIST(params: { key: string; value: InputSuggestionList }) {
        if (!this.filterSettings.filterModel[params.key]) {
            return;
        }
        this.filterSettings.filterModel[params.key].hintsList = params.value;
    }

    @Action({ rawError: true })
    getFilterSettings() {
        return this.filterSettings;
    }

    @Action({ rawError: true })
    setFilterModel(filterModel: {}) {
        this.self.SET_FILTER_MODEL(filterModel);
    }

    @Action({ rawError: true })
    setFilterHandlers(filterHandlers: { update: string; reset: string; resetState: string; removeSelected: string }) {
        this.self.SET_FILTER_HANDLERS(filterHandlers);
    }

    @Action({ rawError: true })
    setFilterName(filterName: string) {
        this.self.SET_FILTER_NAME(filterName);
    }

    @Action({ rawError: true })
    setTemplateClassName(className: string | string[]) {
        this.self.SET_TEMPLATE_CLASS_NAME(className);
    }

    @Action({ rawError: true })
    setBtnClassName(className: string) {
        this.self.SET_BTN_CLASS_NAME(className);
    }

    // search
    @Action({ rawError: true })
    async updateSearch(params: { key: string; value: string }) {
        if (~params.key.indexOf('phone') || ~params.key.indexOf('Phone')) {
            params.value = params.value.replace(/[(\\+\-)\s]/g, '');
            params.value = params.value.length === 11 ? params.value.substr(1) : params.value;
        }

        await this.self.SET_SEARCH(params);
        this.self.saveValueToLocalStorage(params);
    }

    @Action({ rawError: true })
    async resetSearch(key: string) {
        const result = { key, value: '' };
        await this.self.SET_SEARCH(result);
        this.self.saveValueToLocalStorage(result);
    }

    @Action({ rawError: true })
    async resetSearchState(key: string) {
        await this.self.SET_SEARCH({ key, value: '' });
    }

    // select
    @Action({ rawError: true })
    updateSelectList(params: { key: string; list: any }) {
        this.self.SET_SELECT_LIST({ key: params.key, list: params.list });
    }

    @Action({ rawError: true })
    updateSelect(params: { key: string; value: { id: number | string; value: string } }) {
        this.self.SET_SELECT(params);
        this.self.saveValueToLocalStorage(params);
    }

    @Action({ rawError: true })
    resetSelect(key: string) {
        this.self.updateSelect({ key: key, value: { id: 0, value: '' } });
    }

    @Action({ rawError: true })
    resetSelectState(key: string) {
        this.self.SET_SELECT({ key, value: { id: 0, value: '' } });
    }

    @Action({ rawError: true })
    async resetSelectAndUpdate(params: string) {
        await this.self.resetSelect(params);
        await this.self.updateFilter();
    }

    @Action({ rawError: true })
    async updateMultiSelectList(params: { key: string; list: any }) {
        let newList: { id: number; name: string; checked: boolean }[] = params.list;
        const oldList: { id: number; name: string; checked: boolean }[] = this.filterSettings.filterModel[params.key]
            .list;
        const prevValues: number[] = oldList.filter((item) => item.checked).map((item) => item.id);

        newList = newList.map((item) => {
            if (prevValues.includes(item.id)) {
                item.checked = true;
            }

            return item;
        });

        await this.self.SET_MULTI_SELECT_LIST({ key: params.key, list: newList });
    }

    @Action({ rawError: true })
    async updateMultiSelect(params: { key: string; value: { id: number; value: string } }) {
        await this.self.SET_MULTI_SELECT(params);
        const currentValue = this.filterSettings.filterModel[params.key].value;
        await this.self.saveValueToLocalStorage({ key: params.key, value: currentValue });
    }

    @Action({ rawError: true })
    async resetMultiSelect(key: string) {
        this.self.RESET_MULTI_SELECT(key);
        await this.self.saveValueToLocalStorage({ key: key, value: '' });
    }

    @Action({ rawError: true })
    clearSelect(key: string) {
        this.self.SET_SELECT({ key: key, value: { id: 0, value: '' } });
    }

    // checkbox
    @Action({ rawError: true })
    async updateCheckbox(params: { key: string; checkbox: any }) {
        if (params.checkbox === null) {
            await this.self.RESET_CHECKBOX(params.key);

            return;
        }
        await this.self.SET_CHECKBOX(params);
        this.self.saveValueToLocalStorage({
            key: params?.key,
            value: this.filterSettings.filterModel[params.key].list,
        });
    }

    @Action({ rawError: true })
    async updateCheckboxList(params: { key: string; list: any[] }) {
        await this.self.SET_CHECKBOX_LIST(params);
        this.self.saveValueToLocalStorage({ key: params.key, value: this.filterSettings.filterModel[params.key].list });
    }

    @Action({ rawError: true })
    async resetCheckboxList(key: string) {
        const list = this.filterSettings.filterModel[key].list.map((item: { checked: boolean }) => {
            item.checked = false;

            return item;
        });
        await this.self.SET_CHECKBOX_LIST({ key, list });
        await this.self.saveValueToLocalStorage({ key: key, value: this.filterSettings.filterModel[key].list });
    }

    @Action({ rawError: true })
    async resetCheckboxListState(key: string) {
        const list = this.filterSettings.filterModel[key].list.map((item: { checked: boolean }) => {
            item.checked = false;

            return item;
        });
        await this.self.SET_CHECKBOX_LIST({ key, list });
    }

    // radio
    @Action({ rawError: true })
    async updateRadio(params: { key: string; value: string }) {
        const paramsProcessed = { key: params.key, value: parseInt(params.value) };
        await this.self.SET_RADIO(paramsProcessed);

        let currentValue: any = Object.values(this.filterSettings.filterModel[params.key].list).filter(
            (item: any) => item.id === parseInt(params.value),
        );
        currentValue = currentValue && Object.values(currentValue).length > 0 ? currentValue[0] : {};

        let iconClass = '';
        if (params.value === '0' && !currentValue.icon) {
            iconClass = 'icon-is-active';
        }
        if (params.value === '2' && !currentValue.icon) {
            iconClass = 'icon-is-inactive';
        }
        iconClass = currentValue && currentValue.icon ? currentValue.icon : iconClass;
        this.self.SET_RADIO_ICON_CLASS({ key: params.key, className: iconClass });

        if (currentValue.preview) {
            this.self.SET_MODEL_PREVIEW_NAME({ key: params.key, previewName: currentValue.preview });
        }

        this.self.saveValueToLocalStorage(paramsProcessed);
    }

    @Action({ rawError: true })
    resetRadio(key: string) {
        const params = { key, value: 1 };
        this.self.SET_RADIO(params);
        this.self.SET_RADIO_ICON_CLASS({ key, className: '' });

        this.self.saveValueToLocalStorage(params);
    }

    @Action({ rawError: true })
    resetRadioState(key: string) {
        const params = { key, value: 1 };
        this.self.SET_RADIO(params);
        this.self.SET_RADIO_ICON_CLASS({ key, className: '' });
    }

    // suggestions
    @Action({ rawError: true })
    updateSuggestionsSelected(params: FilterInputSuggestionsParamsInterface) {
        this.self.SET_SUGGESTIONS_VALUE({ key: params.key, value: params.value.value });
        this.self.SET_SUGGESTIONS_SELECTED(params);
        this.self.saveValueToLocalStorage(params);
    }

    @Action({ rawError: true })
    updateSuggestionsValue(params: { key: string; value: string }) {
        this.self.SET_SUGGESTIONS_VALUE(params);
    }

    @Action({ rawError: true })
    updateSuggestionsHintsList(params: { key: string; value: InputSuggestionList }) {
        this.self.SET_SUGGESTIONS_HINTS_LIST(params);
    }

    @Action({ rawError: true })
    resetSuggestions(key: string) {
        const params = { key, value: { id: 0, value: '' } };
        this.self.SET_SUGGESTIONS_VALUE({ key, value: '' });
        this.self.SET_SUGGESTIONS_SELECTED(params);
        this.self.SET_SUGGESTIONS_HINTS_LIST({ key, value: [] });
        this.self.saveValueToLocalStorage(params);
    }

    @Action({ rawError: true })
    resetSuggestionsState(key: string) {
        const params = { key, value: { id: 0, value: '' } };
        this.self.SET_SUGGESTIONS_VALUE({ key, value: '' });
        this.self.SET_SUGGESTIONS_SELECTED(params);
    }

    // localStorage routine
    @Action({ rawError: true })
    getLocalStorageName(key: string) {
        return `${this.filterSettings.name}${key[0].toUpperCase()}${key.slice(1)}`;
    }

    @Action({ rawError: true })
    async saveValueToLocalStorage(params: { key: string; value: any }) {
        const localStorageName = await this.self.getLocalStorageName(params.key);

        window.localStorage[localStorageName] = JSON.stringify(params.value);
    }

    @Action({ rawError: true })
    setFilterFromLocalStorage() {
        this.self.SET_FILTER(
            window.localStorage[`${this.filterSettings.name}String`]
                ? JSON.parse(window.localStorage[`${this.filterSettings.name}String`])
                : '',
        );
        this.self.SET_FILTER_PREVIEW(
            window.localStorage[`${this.filterSettings.name}Preview`]
                ? JSON.parse(window.localStorage[`${this.filterSettings.name}Preview`])
                : {},
        );

        const filterModel = this.filterSettings.filterModel;

        this.self.setFilterModeFromLocalStorage(filterModel);
    }

    @Action({ rawError: true })
    setFilterModeFromLocalStorage(filterModel: any) {
        for (const filterName of Object.keys(window.localStorage)) {
            if (filterName.indexOf(this.filterSettings.name) === -1) {
                continue;
            }

            for (const key of Object.keys(filterModel)) {
                try {
                    this.self.setFiltersModelFromLocalStorage({ filterName, key });
                } catch (error) {
                    console.warn(error);
                }
            }
        }
    }

    @Action({ rawError: true })
    setFiltersModelFromLocalStorage(params: { filterName: string; key: string }) {
        const filterModel = this.filterSettings.filterModel;
        const key: string = params.key;
        const filterName: string = params.filterName;

        if (filterName.toLowerCase().indexOf(key.toLowerCase()) === -1) {
            return;
        }

        if (JSON.parse(window.localStorage[filterName]) == null) {
            return;
        }

        if (filterModel[key].type === 'checkbox') {
            try {
                this.self.SET_CHECKBOX_LIST({ key: key, list: JSON.parse(window.localStorage[filterName]) });
            } catch (error) {
                console.warn(error);
                window.localStorage[filterName] = JSON.stringify('');
            }
        }

        if (filterModel[key].type === 'multi-select') {
            let currentValues: string[];

            try {
                currentValues = JSON.parse(window.localStorage[filterName]).split(', ');
            } catch (error) {
                console.warn(error);
                currentValues = [];
                window.localStorage[filterName] = JSON.stringify('');
            }

            const result: { id: number; value: string }[] = filterModel[key].list.filter(
                (item: { checked: boolean; id: number; name: string; value: number }) => {
                    if (currentValues.includes(item.name)) {
                        return { id: item.id, value: item.name };
                    }
                },
            );
            result.forEach((item) => {
                this.self.SET_MULTI_SELECT({ key: key, value: item });
            });
        }

        if (filterModel[key].type === 'select') {
            const storedValue = JSON.parse(window.localStorage[filterName]);
            const result: { id: number; value: string } = storedValue ? storedValue : { id: 0, value: '' };
            this.self.SET_SELECT({ key: key, value: result });
        }

        if (filterModel[key].type === 'text') {
            this.self.SET_SEARCH({ key: key, value: JSON.parse(window.localStorage[filterName]) });
        }

        if (filterModel[key].type === 'radio') {
            this.self.updateRadio({ key: key, value: JSON.parse(window.localStorage[filterName]) });
        }

        if (filterModel[key].type === 'date-picker') {
            for (const dateKey of Object.keys(filterModel[key].list)) {
                if (filterName.toLowerCase().indexOf(dateKey.toLowerCase()) !== -1) {
                    this.self.SET_FILTER_DATE({
                        key: key,
                        dateKey: dateKey,
                        date: JSON.parse(window.localStorage[filterName]),
                    });
                }
            }
        }

        if (filterModel[key].type === 'suggestions') {
            const storedValue = JSON.parse(window.localStorage[filterName]);
            const result: { id: number; value: string } = storedValue ? storedValue : { id: 0, value: '' };
            this.self.SET_SUGGESTIONS_SELECTED({ key: key, value: result });
            this.self.SET_SUGGESTIONS_VALUE({ key: key, value: result.value });
        }
    }

    // date picker
    @Action({ rawError: true })
    updateStartDate(params: { key: string; date: { day: string; month: string; year: string } }) {
        const date = params.date.day ? `${params.date.day}/${params.date.month}/${params.date.year}` : '';
        const dateParams = { key: params.key, dateKey: '', date: date };

        this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'startDate' } });
        this.self.saveValueToLocalStorage({
            key: `${params.key}StartDate`,
            value: this.filterSettings.filterModel[params.key].list.startDate.value,
        });

        if (isDateStartOverDateEnd(date, this.filterSettings.filterModel[params.key].list.endDate.value)) {
            this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'endDate' } });
            this.self.saveValueToLocalStorage({
                key: `${params.key}EndDate`,
                value: this.filterSettings.filterModel[params.key].list.endDate.value,
            });
        }
    }

    @Action({ rawError: true })
    updateEndDate(params: { key: string; date: { day: string; month: string; year: string } }) {
        const date = params.date.day ? `${params.date.day}/${params.date.month}/${params.date.year}` : '';
        const dateParams = { key: params.key, dateKey: '', date: date };

        this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'endDate' } });
        this.self.saveValueToLocalStorage({
            key: `${params.key}EndDate`,
            value: this.filterSettings.filterModel[params.key].list.endDate.value,
        });

        if (isDateStartOverDateEnd(this.filterSettings.filterModel[params.key].list.startDate.value, date)) {
            this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'startDate' } });
            this.self.saveValueToLocalStorage({
                key: `${params.key}StartDate`,
                value: this.filterSettings.filterModel[params.key].list.startDate.value,
            });
        }
    }

    @Action({ rawError: true })
    async resetDates(key: string) {
        this.self.resetDatesState(key);
        await this.self.saveValueToLocalStorage({ key: `${key}EndDate`, value: '' });
        await this.self.saveValueToLocalStorage({ key: `${key}StartDate`, value: '' });
    }

    @Action({ rawError: true })
    resetDatesState(key: string) {
        this.self.SET_FILTER_DATE({ key, dateKey: 'startDate', date: '' });
        this.self.SET_FILTER_DATE({ key, dateKey: 'endDate', date: '' });
    }

    // init
    @Action({ rawError: true })
    async init() {
        const filterModel = this.filterSettings.filterModel;

        let filterEntity: any;

        for (filterEntity of Object.values(filterModel)) {
            if (!filterEntity?.initAction) {
                continue;
            }

            const result = await this.context.dispatch(filterEntity.initAction, {}, { root: true });

            if (filterEntity.name.indexOf('checkbox') !== -1) {
                await this.self.updateCheckboxList({ key: filterEntity.key, list: result });
            }

            if (filterEntity.name.indexOf('multi_select') !== -1) {
                await this.self.updateMultiSelectList({ key: filterEntity.key, list: result });
            }

            if (filterEntity.name.indexOf('select') === 0) {
                this.self.updateSelectList({ key: filterEntity.key, list: result });
            }

            if (filterEntity.name.indexOf('date') !== -1) {
                this.self.updateStartDate({ key: filterEntity.key, date: result.startDate });
                this.self.updateEndDate({ key: filterEntity.key, date: result.endDate });
            }
        }

        this.self.setFilterFromLocalStorage();
    }

    @Action({ rawError: true })
    async prepareFilterFromModal() {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };
        const filterModel = this.filterSettings.filterModel;

        this.self.SET_FILTER_INDEX(2);

        for (let i = 0; i < Object.values(filterModel).length; i++) {
            let result: any;
            const filterEntity: any = Object.values(filterModel)[i];

            if (filterEntity.name.indexOf('checkbox') !== -1) {
                result = await this.self.prepareCheckbox(filterEntity);
            }

            if (filterEntity.name.indexOf('search') !== -1 && filterEntity.value) {
                result = await this.self.prepareSearch(filterEntity);
            }

            if (filterEntity.name.indexOf('multi_select') !== -1 && filterEntity.value.length > 0) {
                result = await this.self.prepareMultiSelect(filterEntity);
            }

            if (
                filterEntity.name.indexOf('select') === 0 &&
                filterEntity.current.value &&
                filterEntity.current.id !== '0'
            ) {
                result = await this.self.prepareSelect(filterEntity);
            }

            if (filterEntity.name.indexOf('date') !== -1) {
                result = await this.self.prepareDatePicker(filterEntity);
            }

            if (filterEntity.name.indexOf('radio') !== -1 && filterEntity.value !== null) {
                result = await this.self.prepareRadio(filterEntity);
            }

            if (
                filterEntity.name.indexOf('suggestions') !== -1 &&
                filterEntity.current.value &&
                filterEntity.current.id !== '0'
            ) {
                result = await this.self.prepareSuggestions(filterEntity);
            }

            if (result) {
                await this.self.SET_FILTER_INDEX(this.self.index + 1);

                filter.string += result.string;
                filter.preview = { ...result.preview, ...filter.preview };
            }
        }

        this.self.SET_FILTER(filter.string);
        this.self.SET_FILTER_PREVIEW(filter.preview);

        return filter;
    }

    @Action({ rawError: true })
    prepareCheckbox(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        if (filterEntity.name.indexOf('checkbox_bool') !== -1) {
            const checkedValue = filterEntity.list[0].checked;

            filter.string = `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${checkedValue}`;
            filter.preview[filterEntity.key] = {
                name: filterEntity.previewName,
                iterable: false,
                iconClass: filterEntity.iconClass || '',
            };

            return filter;
        }

        const checkedValue = filterEntity.list[0].checked ? 1 : 0;

        if (filterEntity.ignoreNull == true && checkedValue === 0) {
            return filter;
        }

        filter.string = `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${checkedValue}`;
        filter.preview[filterEntity.key] = {
            name: filterEntity.previewName,
            iterable: false,
            iconClass: filterEntity.iconClass || '',
        };

        return filter;
    }

    @Action({ rawError: true })
    prepareSearch(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.value}`;
        filter.preview[filterEntity.key] = {
            name: filterEntity.previewName,
            iterable: false,
            iconClass: filterEntity.iconClass || '',
        };

        return filter;
    }

    @Action({ rawError: true })
    prepareMultiSelect(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        let i = 0;
        let previewCounter = 0;
        const filterBase = `&filters[${this.self.index}][id]=${filterEntity.key}`;
        let filterValue = '';
        for (const item of filterEntity.list) {
            if (item.checked == true) {
                previewCounter++;
                filterValue += `&filters[${this.self.index}][value][${i++}]=${item.id}`;
            }
        }

        filter.string = `${filterBase}${filterValue}`;
        filter.preview[filterEntity.key] = { name: `${filterEntity.previewName} ${previewCounter}`, iterable: true };

        return filter;
    }

    @Action({ rawError: true })
    prepareSelect(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.current.id}`;
        filter.preview[filterEntity.key] = {
            name: filterEntity.previewName,
            iterable: false,
            iconClass: filterEntity.iconClass || '',
        };

        return filter;
    }

    @Action({ rawError: true })
    prepareDatePicker(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        Object.entries(filterEntity.list).forEach((entry: any) => {
            if (entry[1].value !== '' && entry[1].value !== 'Выбрать') {
                const value = moment(entry[1].value, 'DD/MM/YY').format('YYYY-MM-DD');
                filter.string += `&filters[${this.index}][id]=${entry[1].key}&filters[${this.index}][value]=${value}`;
                filter.preview[filterEntity.key] = {
                    name: filterEntity.previewName,
                    iterable: false,
                    iconClass: filterEntity.iconClass || '',
                };
                this.self.SET_FILTER_INDEX(this.self.index + 1);
            }
        });

        return filter;
    }

    @Action({ rawError: true })
    prepareRadio(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${
            filterEntity.value ? 1 : 0
        }`;
        filter.preview[filterEntity.key] = {
            name: filterEntity.previewName,
            iterable: false,
            iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);

        return filter;
    }

    @Action({ rawError: true })
    prepareSuggestions(filterEntity: any) {
        const filter: { string: string; preview: any } = {
            string: '',
            preview: {},
        };

        filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.current.id}`;
        filter.preview[filterEntity.key] = {
            name: filterEntity.previewName,
            iterable: false,
            iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);

        return filter;
    }

    // update
    @Action({ rawError: true })
    async updateFilter() {
        await this.self.prepareFilterFromModal();

        if (this.filterSettings.getListAction) {
            await this.context.dispatch(this.filterSettings.getListAction, {}, { root: true });
        }
    }

    // reset
    @Action({ rawError: true })
    async removeSelectedFilter(params: any) {
        const filterEntity = this.filterSettings.filterModel[params];
        const filterType = filterEntity.type;

        if (filterType === 'text') {
            await this.self.resetSearch(params);
        }

        if (filterType === 'select') {
            await this.self.resetSelect(params);
        }

        if (filterEntity.type === 'checkbox') {
            await this.self.resetCheckboxList(params);
        }

        if (filterType === 'multi-select') {
            await this.self.resetMultiSelect(params);
        }

        if (filterType === 'radio') {
            await this.self.resetRadio(params);
        }

        if (filterType === 'date-picker') {
            for (const dateKey of Object.keys(filterEntity.list)) {
                this.context.dispatch(
                    filterEntity.list[dateKey].action,
                    { key: filterEntity.key, date: { day: '', month: '', year: '' } },
                    { root: true },
                );
            }
        }

        if (filterType === 'suggestions') {
            await this.self.resetSuggestions(params);
        }

        this.self.DEL_FILTER_PREVIEW(params);
        await this.self.updateFilter();
    }

    @Action({ rawError: true })
    async resetFilter() {
        await this.self.SET_FILTER('');
        await this.self.SET_FILTER_PREVIEW({});
        await this.self.SET_PAGE(1);

        await this.self.resetFilterValues();
        if (this.filterSettings.getListAction) {
            await this.context.dispatch(this.filterSettings.getListAction, {}, { root: true });
        }
    }

    @Action({ rawError: true })
    async resetFilterValues() {
        for (const filterEntity of Object.values(this.filterSettings.filterModel)) {
            if (filterEntity.type === 'checkbox') {
                await this.self.resetCheckboxList(filterEntity.key);
            }

            if (filterEntity.type === 'text') {
                await this.self.resetSearch(filterEntity.key);
            }

            if (filterEntity.type === 'select') {
                await this.self.resetSelect(filterEntity.key);
            }

            if (filterEntity.type === 'multi-select') {
                await this.self.resetMultiSelect(filterEntity.key);
            }

            if (filterEntity.type === 'radio') {
                await this.self.resetRadio(filterEntity.key);
            }

            if (filterEntity.type === 'date-picker') {
                await this.self.resetDates(filterEntity.key);
            }

            if (filterEntity.type === 'suggestions') {
                await this.self.resetSuggestions(filterEntity.key);
            }
        }

        this.self.updateFilter();
    }

    @Action({ rawError: true })
    async resetFilterState() {
        for (const filterEntity of Object.values(this.filterSettings.filterModel)) {
            if (filterEntity.type === 'checkbox') {
                await this.self.resetCheckboxListState(filterEntity.key);
            }

            if (filterEntity.type === 'text') {
                await this.self.resetSearchState(filterEntity.key);
            }

            if (filterEntity.type === 'select') {
                await this.self.resetSelectState(filterEntity.key);
            }

            if (filterEntity.type === 'multi-select') {
                await this.self.RESET_MULTI_SELECT(filterEntity.key);
            }

            if (filterEntity.type === 'radio') {
                await this.self.resetRadioState(filterEntity.key);
            }

            if (filterEntity.type === 'date-picker') {
                await this.self.resetDatesState(filterEntity.key);
            }

            if (filterEntity.type === 'suggestions') {
                await this.self.resetSuggestionsState(filterEntity.key);
            }
        }
    }
}
