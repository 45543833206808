import { Component, Vue, Watch } from 'vue-property-decorator';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

@Component
export default class MultipleFilesUploadMixin extends Vue {
    errorType = '';
    newImagesTotal = 0;
    newImagesLoaded = 0;
    messages: { [key: string]: string } = {
        extension: 'Ошибка! Неверный формат файла(ов)',
        dimensions: 'Изображение не должно быть меньше 480x480 пикселей',
    };
    erroredFiles: string[] = [];
    validFiles: File[] = [];

    @Watch('newImagesLoaded')
    onNewImagesLoaded(val: number): void {
        if (this.newImagesTotal && val !== this.newImagesTotal) {
            return;
        }

        if (this.errorType) {
            ResponseHandlerModule.showNotify({
                message: `${this.messages[this.errorType]}: ${this.erroredFiles.join(', ')}`,
                type: 'fail',
            });
        }

        this.$emit('change', this.validFiles);
        (this.$refs.imageInput as HTMLInputElement).value = '';
    }

    async onChange(event: Event): Promise<void> {
        this.errorType = '';
        this.validFiles = [];
        this.erroredFiles = [];
        this.newImagesTotal = 0;
        this.newImagesLoaded = 0;

        const target = event.target as HTMLInputElement;

        if (!target.files) {
            return;
        }

        const filesValues = Object.values(target.files);

        this.newImagesTotal = filesValues.length;

        filesValues.forEach((file) => {
            if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
                this.errorType = 'extension';
                this.erroredFiles.push(file.name);

                return;
            }

            const fr = new FileReader();

            fr.onload = () => {
                const img = new Image();

                img.onload = () => {
                    if (img.width < 480 || img.height < 480) {
                        this.errorType = 'dimensions';

                        this.erroredFiles.push(file.name);
                        this.newImagesLoaded++;

                        return;
                    }

                    this.validFiles.push(file);
                    this.newImagesLoaded++;
                };

                img.src = fr.result as string;
            };

            fr.readAsDataURL(file);
        });
    }
}
