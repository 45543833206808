import { TableApiInterface, TableInterface } from './layouts/page/table.interface';
import { formatCreatedAt, formatNumberSpaced } from './utils/Utils';
import UserModule from '@/admin/store/user';
import { YesNoMap } from '@/constants';

export function prepareTable(table: TableApiInterface, statuses: { id: string; name: string }[]) {
    let hiddenColumns = table.titles.filter((title) => title.visible === false).map((title) => title.id);
    hiddenColumns = [...hiddenColumns, ...['sellerType', 'sellerId']];

    const rows: any = [];
    const defaultStatusClasses = ['cp-text__center', 'cp-padding__table-label', 'cp-color-white'];
    let additionalStatusClasses = ['cp-bg-default'];

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];

            if (i === 'id') {
                cell = `<a href="${window.location.origin}/auctions/order/${cell}" class="cp-table__item-link">
                    <span class="cp-table-column__bold">${cell}</span>
                </a>`;
            }

            if (i === 'status') {
                const status = statuses.filter((item) => item.id === cell)[0].name;
                if (cell === 'not_paid') {
                    additionalStatusClasses = ['cp-bg-red'];
                }

                if (cell === 'paid_through_site' || cell === 'paid_in_another' || cell === 'paid_directly_to_seller') {
                    additionalStatusClasses = ['cp-bg-green'];
                }

                cell = `<a href="${window.location.origin}/auctions/order/${row.id}" class="cp-link-no-affect">
                    <div class="${[...defaultStatusClasses, ...additionalStatusClasses].join(' ')}">${status}</div>
                </a>`;

                additionalStatusClasses = ['cp-bg-default'];
            }

            if (i === 'costOrder') {
                cell = formatNumberSpaced(cell);
            }

            if (i === 'customer' && UserModule.isSuperAdmin) {
                cell = `<a href="${window.location.origin}/users/user/${cell.id}/" class="cp-table__item-link">
                            <span class="cp-table-column__bold">${cell.name}</span>
                        </a>`;
            }

            if (i === 'customer' && !UserModule.isSuperAdmin) {
                cell = cell.name;
            }

            if (i === 'sellerName') {
                cell = `<a href="${window.location.origin}/sellers/${row['sellerType']}/${row['sellerId']}" class="cp-table__item-link">
                            <span class="cp-table-column__bold">${cell}</span>
                        </a>`;
            }

            rowProcessed[i] = cell;
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: table.titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}

/**
 * Returns processed order lots table info
 *
 * @param {Object} table shops data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareOrderLotsTable(
    table: TableApiInterface,
    statuses: { id: string; name: string }[],
): TableInterface {
    const hiddenColumnsTitles = ['priceSell', 'priceStart', 'sellerId', 'sellerTypeName'];
    const hiddenColumns = Object.values(table.titles)
        .filter((title) => !title.visible || hiddenColumnsTitles.includes(title.id))
        .map((title) => title.id);

    const rows: any = [];

    let index: string;
    let row: any;
    for ([index, row] of Object.entries(table.rows)) {
        const rowProcessed: any = {};
        for (const i of Object.keys(row)) {
            let cell: any = row[i];
            const sellerType = row['sellerType'];
            const lotId = row['id'];

            if (i === 'name') {
                cell = lotId
                    ? `<a href="${window.location.origin}/auctions/lot/${sellerType}/${lotId}" target="_blank" class="cp-table__item-link">
                            <span class="cp-table-column__accent">${cell}</span>
                        </a>`
                    : cell;
            }

            if (i === 'sellerName') {
                cell = `<a
                            href="${window.location.origin}/sellers/${row['sellerType']}/${row['sellerId']}"
                            target="_blank"
                            class="cp-table__item-link"
                        >
                            ${cell}
                        </a>`;
            }

            if (i === 'status') {
                const status = statuses.find((status) => status.id === cell)?.name;
                cell = status ?? cell;
            }

            if (i === 'dateTimeStartTrad') {
                cell = cell ? formatCreatedAt(cell) : '-';
            }

            if (i === 'datetimePosting') {
                cell = cell ? formatCreatedAt(cell) : '-';
            }

            if (i === 'isActive' || i === 'isPublished') {
                cell = YesNoMap.get(cell);
            }

            if (['isHideImage', 'isHidePrice'].includes(i)) {
                cell = YesNoMap.get(cell);
            }

            if (i === '_actions') {
                cell = ['add'];
            }

            rowProcessed[i] = cell;
        }

        rows[index] = rowProcessed;
    }

    return {
        titles: table.titles,
        rows: rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
}
