
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ImageInputMixin from './Mixin';
import MultipleFilesUploadMixin from './MultipleFilesUploadMixin';
import Cropper from '@/components/modules/Cropper.vue';
import { StringRecord, uploadImage } from '@/admin/api/file';
import SiteModule from '@/admin/store/site';
import { imageUrlToFile } from '@/lib/utils/Utils';

@Component({
    components: { Cropper },
})
export default class ImageInput extends Mixins(MultipleFilesUploadMixin, ImageInputMixin) {
    @Prop({ default: '' }) entity!: string;
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false, type: Boolean }) square!: boolean;

    isCropperShow = false;
    isCroppEditor = true;

    get isBlockSite(): boolean {
        return SiteModule.isBlock;
    }

    async handleChange(event: Event): Promise<void> {
        this.multiple ? await this.onChange(event) : await this.loadImage(event);

        setTimeout(() => {
            this.openCropper();
        }, 1000);
    }

    removeImage(): void {
        const input = document.getElementById(`image_input_${this.data.key}`) as HTMLInputElement;
        if (input) {
            input.value = '';
        }

        this.isCroppEditor = this.data.value ? true : false;

        this.$emit('remove-preview');

        return;
    }

    showPhotoCtrl(e: Event): void {
        const target = e.target as HTMLElement;
        if (!target.parentElement) {
            return;
        }
        target.parentElement.classList.add('hover');
        target.parentElement.querySelector('.cp-image-input__label-ctrl-image')?.classList.add('visible');
    }

    hidePhotoCtrl(e: Event): void {
        const target = e.target as HTMLElement;
        if (!target.parentElement) {
            return;
        }
        target.parentElement.classList.add('hover');
        target.parentElement.querySelector('.cp-image-input__label-ctrl-image')?.classList.remove('visible');
    }

    async handleCrop(dimensions: StringRecord): Promise<void> {
        if (this.entity) {
            const preview: any = this.data.preview ? await imageUrlToFile(this.data.preview) : '';
            const image: any = await uploadImage(preview || this.data.value, dimensions, this.entity);

            this.$emit('load-image', image?.files?.[0]);

            this.isCropperShow = false;
            this.isCroppEditor = true;
        }
    }

    openCropper(): void {
        this.isCropperShow = true;
    }

    closeCropper(): void {
        this.isCropperShow = false;
    }
    
    @Watch('isBlockSite')
    onUpdateIsBlockSite(value: boolean) {
        if (!value) {
            this.isCroppEditor = true;
        }
    }
}
