
import { Radio } from '@/lib/formFactory/radio.interface';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class RadioBtnComponent extends Vue {
    @Prop({ required: true }) readonly data!: Radio;
    @Prop({ default: false }) disabled!: boolean;

    updateValue(e: Event) {
        this.$emit('click', (e.target as HTMLFormElement).value);
    }
}
