
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import ModalBlock from '@/components/Modal.vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

type RecordKey = 'top' | 'left' | 'width' | 'height';

type NumberRecord = Record<RecordKey, number>;

interface CropperInterface extends Element {
    getCropBoxData(): NumberRecord;
    reset(): void;
}

@Component({
    components: {
        ModalBlock,
        VueCropper,
    },
})
export default class CropperComponent extends Vue {
    @Prop() readonly url!: string;
    @Prop({ default: false, type: Boolean }) square!: boolean;
    @Prop({ type: Number, default: 0 }) minHeight!: number;
    @Prop({ type: Number, default: 0 }) minWidth!: number;
    @Prop({ type: Boolean, default: false }) fullSize!: boolean;
    @Ref('cropper') cropper!: HTMLDivElement;

    title = 'Изменить изображение';
    imgWrapperOffsetX = 0;
    imgWrapperWidth = 0;
    imgWrapperHeight = 0;
    width = 0;
    height = 0;
    left = 0;
    top = 0;

    get aspectRatio(): number {
        if (this.square) {
            return 1 / 1;
        }

        return NaN;
    }

    get disabled(): boolean {
        if (!this.minWidth || !this.minHeight) {
            return false;
        }

        return Math.round(this.width) < this.minWidth || Math.round(this.height) < this.minHeight;
    }

    get autoCropArea(): number {
        if (this.fullSize) {
            return 1;
        }

        return 0.8;
    }

    apply(): void {
        const data = (this.$refs.cropper as CropperInterface).getCropBoxData();
        // crop coords for image element in crop window
        data.left = Math.round(data.left - this.imgWrapperOffsetX);
        data.top = Math.round(data.top);
        data.width = Math.round(data.width);
        data.height = Math.round(data.height);

        // const onePercentWidthCropped = this.imgWrapperWidth / 100;
        // const onePercentHeightCropped = this.imgWrapperHeight / 100;

        // const percentage = {
        //     left: data.left / onePercentWidthCropped,
        //     top: data.top / onePercentHeightCropped,
        //     width: data.width / onePercentWidthCropped,
        //     height: data.height / onePercentHeightCropped,
        // };

        const img = new Image();
        img.onload = () => {
            // original image sizes
            // const imgSizes = {
            //     width: img.width,
            //     height: img.height,
            // };

            // const onePercentWidthOriginal = imgSizes.width / 100;
            // const onePercentHeightOriginal = imgSizes.height / 100;

            this.$emit('crop', {
                xStart: this.left,
                yStart: this.top,
                width: Math.round(this.width),
                height: Math.round(this.height),
            });
        };

        img.src = this.url;
    }

    reset(): void {
        (this.$refs.cropper as CropperInterface).reset();
    }

    closeModal(): void {
        this.$emit('click');
    }

    readyHandler(): void {
        const imageWrapper = document.getElementsByClassName('cropper-canvas')[0];
        const style = window.getComputedStyle(imageWrapper);

        const [width, height] = [style.width.match(/\d+/), style.height.match(/\d+/)].map((match) =>
            match ? +match[0] : 0,
        );

        this.imgWrapperWidth = width;
        this.imgWrapperHeight = height;

        const matrix = style.transform;
        const match = matrix.match(/matrix.*\((.+)\)/);

        if (!match) {
            return;
        }

        const matrixValues = match[1].split(', ');

        this.imgWrapperOffsetX = +matrixValues[4] || 0;
    }

    onCrop(e:any) {
        this.width = e.detail.width;
        this.height = e.detail.height;
        this.top = e.detail.y;
        this.left = e.detail.x;
    }
}
