
import { Vue, Component, Prop } from 'vue-property-decorator';
import { languageIcons } from '@/constants';

@Component
export default class BtnLocalization extends Vue {
    @Prop({ default: 'ru' }) currentLanguage!: string;

    icons = languageIcons;

    switchLanguage(): void {
        this.$emit('click');
    }
}
