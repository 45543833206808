
import { Component, Mixins } from 'vue-property-decorator';
import InputLocalizationMixin from './InputLocalizationMixin';
import InputBlock from './InputMain.vue';

@Component({
    components: {
        InputBlock,
    },
})
export default class InputLocalization extends Mixins(InputLocalizationMixin) {}
