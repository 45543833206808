
import { Component, Prop, Vue } from 'vue-property-decorator';
import Editor from '@/components/modules/Editor.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import ModalBlock from '@/components/Modal.vue';

export interface EditorProp {
    title: string;
    value: string;
    key: string;
    error: {
        class: string;
        message: string;
    };
}

@Component({
    components: {
        Editor,
        BtnDefault,
        ModalBlock,
    },
})
export default class EditorModal extends Vue {
    @Prop({ required: false }) readonly data?: EditorProp | null;
    @Prop({ required: false }) readonly title?: string;
    @Prop() readonly isLocalization?: boolean;

    text = '';

    updateText(value: string): void {
        if (this.isLocalization) {
            this.$emit('input', value);

            return;
        }

        this.text = value;
    }

    save(): void {
        this.$emit('save', this.text);

        if (!this.isLocalization) {
            this.closeModal();
        }
    }

    closeModal() {
        this.$emit('click');
    }

    mounted() {
        this.updateText(this.data?.value || '');
    }

    beforeDestroy() {
        this.updateText('');
    }
}
