
import { Mixins, Component, Watch } from 'vue-property-decorator';

import SiteModule from '@/admin/store/site';

import TableTitle from './_tableTitle.vue';
import PaginationBlock from '@/components/Pagination.vue';
import IconFont from '@/components/icons/IconFont.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import BtnSubmit from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';

import tableMixin from './tableMixin';
import SelectEntity from '@/lib/formFactory/selectEntity';
import { Select, SelectItem } from '@/lib/formFactory/select.interface';
import { getApiErrorMessage, LotErrorInterface, showError, showMessage } from '@/lib/utils/Utils';
import { TableRow, TableRows } from '@/lib/layouts/page/table.interface';
import { deleteMultipleLots } from '@/admin/api/lots';

const actionsList = [{ id: 'delete', value: 'Удалить' }];

@Component({
    components: {
        TableTitle,
        PaginationBlock,
        IconFont,
        SelectCustom,
        BtnSubmit,
    },
})
export default class TableLotsComponent extends Mixins(tableMixin) {
    statusModel: Select | null = null;
    isShowApply = false;
    errorIds: number[] = [];

    get isShowSelect(): boolean {
        return !!(this.rows as TableRows).filter((row) => row.checked).length;
    }

    @Watch('pagination')
    onCurrentPageChange(): void {
        this.errorIds = [];
    }

    updateStatus(selected: SelectItem): void {
        if (!this.statusModel) {
            return;
        }

        if (this.statusModel.current.id === selected.id) {
            return;
        }

        this.statusModel.current = selected;
        this.isShowApply = true;
    }

    prepareParams(): string {
        return (this.rows as TableRow[])
            .filter((row) => row.checked)
            .map((row, index) => `ids[${index}]=${row.id}`)
            .join('&');
    }

    async apply(): Promise<void> {
        try {
            SiteModule.SET_IS_BLOCK(true);

            const params = this.prepareParams();
            if (!params.length) {
                throw new Error('Ошибка обновления лотов');
            }

            const result = await deleteMultipleLots(params);
            if (result === 'ok') {
                showMessage('Лоты удалены');
            }

            this.errorIds = [];

            SiteModule.SET_IS_BLOCK(false);
        } catch (error: any) {
            SiteModule.SET_IS_BLOCK(false);

            if (error?.response?.data?.errors) {
                this.errorIds = error?.response?.data?.errors.map((error: LotErrorInterface) => +error.lotId);
            }

            showError(getApiErrorMessage(error));
        }
    }

    async created(): Promise<void> {
        const statusEntity = new SelectEntity('status');
        statusEntity.setList(actionsList);
        statusEntity.setCurrent({ id: 0, value: 'Выберите действие' });
        this.statusModel = statusEntity.entity[statusEntity.entityName];
    }
}
