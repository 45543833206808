import InputEntity from '@/lib/formFactory/inputEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const operationTypeEntity = new RadioEntity('operationType');
        operationTypeEntity.setTitle('');
        operationTypeEntity.setList([
            { id: 0, name: 'Поступление', value: 'receipt' },
            { id: 1, name: 'Выплата', value: 'pay' },
        ]);
        operationTypeEntity.setRequired(true);
        operationTypeEntity.setValue('receipt');

        const sumEnity = new InputEntity('sum');
        sumEnity.setTitle('Сумма');
        sumEnity.setRequired(true);

        const sellerTypeEntity = new SelectEntity('sellerType');
        sellerTypeEntity.setTitle('Тип продавца');
        sellerTypeEntity.setList([
            { id: 'sellerAuctionHouse', value: 'Аукционный дом' },
            { id: 'sellerGallery', value: 'Галерея' },
            { id: 'sellerPainter', value: 'Автор' },
        ]);
        sellerTypeEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerTypeEntity.setRequired(true);

        const sellerGalleryEntity = new InputSuggestionsEntity('sellerGallery');
        sellerGalleryEntity.setTitle('Галерея');
        sellerGalleryEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerGalleryEntity.setRequired(true);
        sellerGalleryEntity.setDisabled(true);

        const commentEnity = new InputEntity('comment');
        commentEnity.setTitle('Комментарий');

        this.model = {
            operationType: operationTypeEntity.entity[operationTypeEntity.entityName],
            sum: sumEnity.entity[sumEnity.entityName],
            comment: commentEnity.entity[commentEnity.entityName],
            sellerType: sellerTypeEntity.entity[sellerTypeEntity.entityName],
            sellerGallery: sellerGalleryEntity.entity[sellerGalleryEntity.entityName],
        };
    }
}
