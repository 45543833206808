
import { Vue, Component } from 'vue-property-decorator';
import SellersGalleryEntity from '@/admin/store/sellers/galleries/entity';
import { GalleryModelInterface } from '@/interfaces/models/gallery.interface';
import { imageToFile } from '@/lib/utils/Utils';

import InputBlock from '@/components/form/InputMain.vue';
import ImageBlock from '@/components/imageInput/ImageInput.vue';

import Inputmask from 'inputmask';

@Component({
    components: {
        InputBlock,
        ImageBlock,
    },
})
export default class GalleryRequisiteComponent extends Vue {
    get model(): GalleryModelInterface {
        return SellersGalleryEntity.model;
    }

    updateBillName(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billName', value });
    }

    updateBillAddress(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billAddress', value });
    }

    updateBillPhone(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billPhone', value });
    }

    updateBillInn(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billInn', value });
    }

    updateBillKpp(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billKpp', value });
    }

    async handleImage(file: File): void {
        const toFile = await imageToFile(file);

        SellersGalleryEntity.updateModelImageFieldFile({ field: 'billLogo', file: toFile });
    }

    removePreview(): void {
        SellersGalleryEntity.SET_MODEL_IMAGE_FIELD_FILE({ field: 'billLogo', file: null });
        SellersGalleryEntity.SET_MODEL_IMAGE_FIELD_PREVIEW({ field: 'billLogo', base64String: '' });
    }

    updateBillBank(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billBank', value });
    }

    updateBillBik(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billBik', value });
    }

    updateBillAccount(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billAccount', value });
    }

    updateBillCorrespondentAccount(value: string): void {
        SellersGalleryEntity.updateModelFieldValue({ field: 'billCorrespondentAccount', value });
    }

    initMask(): void {
        const settingsMask = new Inputmask({ regex: '\\d+', showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_num_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            settingsMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    mounted(): void {
        this.initMask();
    }
}
