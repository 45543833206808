/**
 * Transaction entity base module
 *
 */

import { Mutation, Action } from 'vuex-module-decorators';

import EntityBaseModule from '@/admin/store/entity';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { cancelTransaction, createTransaction } from '@/admin/api/transaction';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { getCatalogAuctionList } from '../api/catalogAuction';
import { processListForSelect } from '@/lib/utils/form';
import { getStatistics } from '../api/statistics';
import { generateFilter, getApiErrorMessage, showError } from '@/lib/utils/Utils';

export default abstract class BalanceSellerEntity extends EntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';
    isSendBlocked = false;

    constructor(module: BalanceSellerEntity) {
        super(module);
    }

    @Mutation
    SET_IS_SEND_BLOCKED(bool: boolean): void {
        this.isSendBlocked = bool;
    }

    @Mutation
    SET_OPERATION_TYPE(value: string): void {
        this.model.operationType.value = value;
    }

    @Mutation
    SET_SUM(sum: string): void {
        this.model.sum.value = sum;
    }

    @Mutation
    SET_SELLER_LINK_ID(id: number | null): void {
        this.model.sellerLink.id = id;
    }

    @Mutation
    SET_SELLER_LINK_NAME(name: string): void {
        this.model.sellerLink.name = name;
    }

    @Mutation
    SET_SELLER_LINK_TYPE(type: string): void {
        this.model.sellerLink.type = type;
    }

    @Mutation
    SET_SELLER_LINK_PARAM_NAME(name: string): void {
        this.model.sellerLink.paramName = name;
    }

    @Mutation
    SET_COST_LOTS(cost: number | string): void {
        this.model.orderDetails.costLots.value = cost;
    }

    @Mutation
    SET_COST_DELIVERY(cost: number | string): void {
        this.model.orderDetails.costDelivery.value = cost;
    }

    @Mutation
    SET_COMMISSION_RIGHT_TO_FOLLOW(commission: number | string): void {
        this.model.orderDetails.commissionRightToFollow.value = commission;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(commission: number | string): void {
        this.model.orderDetails.commissionAuctionHouse.value = commission;
    }

    @Mutation
    SET_COMMISSION_FINANCIAL_SERVICES(commission: number | string): void {
        this.model.orderDetails.commissionFinancialServices.value = commission;
    }

    @Mutation
    SET_COMMISSION_SITE(commission: number | string): void {
        this.model.orderDetails.commissionSite.value = commission;
    }

    @Mutation
    SET_COMMISSION_ACQUIRING(commission: number | string): void {
        this.model.orderDetails.commissionAcquiring.value = commission;
    }

    @Mutation
    SET_CATALOG_IS_REQUIRED(bool: boolean): void {
        this.model.catalog.required = bool;
    }

    @Mutation
    SET_ORDER_DETAILS_ITEM(params: { key: string; value: string }): void {
        if (!this.model.orderDetails?.[params.key]) {
            return;
        }

        this.model.orderDetails[params.key].value = params.value;
    }

    @Action({ rawError: true })
    updateOperationType(type: string): void {
        this.context.commit('SET_OPERATION_TYPE', type);
    }

    @Action({ rawError: true })
    updateSum(sum: string): void {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sum');
        this.context.commit('SET_SUM', sum);
    }

    @Action({ rawError: true })
    updateSelectedCustomer(selected: InputSuggestionItem): void {
        this.context.commit('RESET_ERROR_BY_FIELD', 'customer');
        this.context.dispatch('updateSuggestionsSelectedByType', { type: 'customer', selected });
    }

    @Action({ rawError: true })
    async updateSearchCatalog(search: string): Promise<void> {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { type: 'catalog', value: search });

        const filterItems = [{ id: 'withRemoved', value: '1' }];

        if (search) {
            filterItems.push({ id: 'name', value: search });
        }

        if (this.model.sellerAuctionHouse.current.id) {
            filterItems.push({ id: 'sellerAuctionHouseId', value: this.model.sellerAuctionHouse.current.id });
        }

        const table = await getCatalogAuctionList(1, this.requestSort, generateFilter(filterItems), 50);
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', {
            type: 'catalog',
            list: processListForSelect(table.rows),
        });
    }

    @Action({ rawError: true })
    async updateSelectedCatalog(selected: InputSuggestionItem): Promise<void> {
        this.context.dispatch('updateSuggestionsSelectedByType', { type: 'catalog', selected });

        const catalogStatistics = await getStatistics('auction', +selected.id, '');

        if (this.model.orderDetails == null) {
            return;
        }

        let total = 0;
        Object.keys(catalogStatistics).forEach((key) => {
            if (!this.model.orderDetails[key]) {
                return;
            }

            total += +catalogStatistics[key];
            this.context.commit('SET_ORDER_DETAILS_ITEM', { key, value: String(catalogStatistics[key]) });
            this.context.commit('SET_ORDER_DETAILS_ITEM', {
                key: 'total_catalog_balance',
                value: `${catalogStatistics.amount} / ${total}`,
            });
        });
        this.context.commit('SET_IS_LOADING', false);
    }

    @Action({ rawError: true })
    async validate(): Promise<boolean> {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);
        const seller = await this.context.dispatch('getCurrentSellerEntity');

        this.context.dispatch('setValidateState', { index: 'sum', field: this.model.sum });
        this.context.dispatch('setValidateSelect', { index: 'sellerType', field: this.model.sellerType });
        this.context.dispatch('setValidateSelect', { index: this.model.sellerType.current.id, field: seller });
        this.context.dispatch('setValidateRadio', { index: 'operationType', field: this.model.operationType });

        if (this.model.catalog) {
            this.context.dispatch('setValidateSelect', { index: 'catalog', field: this.model.catalog });
        }

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    getCurrentSellerEntity() {
        return this.model.sellerAuctionHouse || this.model.sellerGallery || this.model.sellerPainter;
    }

    @Action({ rawError: true })
    async prepareData(): Promise<{ [key: string]: string | number | undefined }> {
        const data: {
            operationType: string;
            sum: number;
            comment?: string;
            sellerPainter?: number;
            sellerGallery?: number;
            sellerAuctionHouse?: number;
            auction?: number;
        } = {
            operationType: this.model.operationType.value,
            sum: this.model.sum.value,
        };

        if (this.model.comment.value) {
            data['comment'] = this.model.comment.value;
        }

        if (this.model.sellerPainter?.current.id) {
            data['sellerPainter'] = this.model.sellerPainter.current.id;
        }

        if (this.model.sellerGallery?.current.id) {
            data['sellerGallery'] = this.model.sellerGallery.current.id;
        }

        if (this.model.sellerAuctionHouse?.current.id) {
            data['sellerAuctionHouse'] = this.model.sellerAuctionHouse.current.id;
        }

        if (this.model.catalog?.current.value) {
            data['auction'] = this.model.catalog.current.id;
        }

        return data;
    }

    @Action({ rawError: true })
    async send(): Promise<void | boolean> {
        const isError = await this.context.dispatch('validate');

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return isError;
        }

        this.context.commit('SET_IS_SEND_BLOCKED', true);
        try {
            const data = await this.context.dispatch('prepareData');
            const result = await createTransaction(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.context.dispatch('resetAfterSend');
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
        this.context.commit('SET_IS_SEND_BLOCKED', false);
    }

    @Action({ rawError: true })
    async cancelTransaction(transactionId: number): Promise<void> {
        try {
            const result = await cancelTransaction(transactionId);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: `Транзакция №${transactionId} отменена`, type: 'ok' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    resetAfterSend(): void {
        this.context.commit('SET_SUM', '');
        this.context.commit('SET_COMMENT', '');

        if (this.model.catalog) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'catalog',
                selected: { id: 0, value: '' },
            });
        }
    }

    @Action({ rawError: true })
    reset(): void {
        this.context.commit('SET_OPERATION_TYPE', 'receipt');
        this.context.commit('SET_SUM', '');
        this.context.commit('SET_COMMENT', '');

        if (this.model.sellerPainter) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'sellerPainter',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'sellerPainter', list: [] });
        }

        if (this.model.sellerGallery) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'sellerGallery',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'sellerGallery', list: [] });
        }

        if (this.model.sellerAuctionHouse) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'sellerAuctionHouse',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'sellerAuctionHouse', list: [] });
        }

        if (this.model.catalog) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'catalog',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'catalog', list: [] });
        }
    }

    @Action({ rawError: true })
    resetErrorState(): void {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sum');

        if (this.model.sellerPainter) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'sellerPainter');
        }

        if (this.model.sellerGallery) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'sellerGallery');
        }

        if (this.model.sellerAuctionHouse) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouse');
        }

        if (this.model.catalog) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'catalog');
        }

        if (this.model.customer) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'customer');
        }
    }
}
