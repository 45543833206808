<template>
    <div class="mr-checkbox-btn__wrapper mr-checkbox-icons__wrapper">
        <span v-if="data.title" class="mr-checkbox-btn-title">{{ data.title }}</span>

        <div class="mr-checkbox-btn__inputs justify-content-start">
            <div
                v-for="(item, index) in data.list"
                :key="`${data.name}_${item.id}_${index}`"
                class="mr-checkbox-btn__group-icons cp-margin__r--sx"
                :class="data.error.class"
            >
                <input
                    :id="`${data.name}_${item.id}`"
                    type="radio"
                    :name="data.name"
                    :value="item.id"
                    :checked="item.value === data.value"
                    @change="updateValue"
                />

                <label :for="`${data.name}_${item.id}`" class="cp-checkbox-btn__icon">
                    <icon-font size="22" :icon="item.icon" />

                    <span class="cp-checkbox-btn__name">{{ item.name }}</span>
                </label>
            </div>
        </div>

        <span class="mr-error-message">{{ data.error.message }}</span>
    </div>
</template>

<script>
import IconFont from '@/components/icons/IconFont.vue';

export default {
    components: {
        IconFont,
    },
    props: ['data'],
    methods: {
        updateValue(e) {
            this.$emit('click', e.target.value);
        },
    },
};
</script>
