
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TableBlock from '@/components/table/TableLots.vue';
import Loading from '@/components/Loading.vue';

import LotsModule from '@/admin/store/sellers/galleries/lots';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';

@Component({
    components: {
        FlexLayout,
        AppHeaderMain,
        TableBlock,
        Loading,
    },
})
export default class GalleryLotsComponent extends Vue {
    get settings(): PageInterface {
        return LotsModule.pageSettings;
    }

    get filterSettings(): FilterSettings {
        return LotsModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return LotsModule.isLoading;
    }

    get paginationAction(): (number: string) => void {
        return LotsModule.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: LotsModule.pageSettings.pageAmountItems,
        };
    }

    async selectAmount(items: string): Promise<void> {
        await LotsModule.updateItems(items);
        this.updateList();
    }

    tableAction(params: any): void {
        if (params.action === 'sort') {
            LotsModule.sort({ field: params.field, sort: params.sort });
        }

        if (params.action === 'checkbox') {
            LotsModule.UPDATE_ROW_CHECKED(params.id);
        }

        if (params.action === 'check-all') {
            LotsModule.toggleCheckAll();
        }
    }

    updateList(): void {
        LotsModule.getList();
    }

    async resetFilters(): Promise<void> {
        await LotsModule.clearSort();
        LotsModule.getList();
    }

    toAdd(): void {
        const query = Number.isNaN(+this.$route.params.galleryId) ? {} : { galleryId: this.$route.params.galleryId };

        this.$router.push({ name: 'lot_create', params: { sellerType: 'gallery' }, query });
    }

    created(): void {
        LotsModule.init(this.$route.params.galleryId);
    }

    beforeDestroy(): void {
        LotsModule.reset();
    }
}
