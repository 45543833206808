import { makeFormRequest } from '@/api/helpers';

type RecordKey = 'yStart' | 'xStart' | 'width' | 'height';

export type StringRecord = Record<RecordKey, string>;

export const uploadImage = async (url: string | File, dimensions: StringRecord, entity: string): Promise<unknown> => {
    const result = await makeFormRequest('/api/admin/crop/upload-image/', {
        'photos[]': url,
        ...dimensions,
        entity,
    });

    return result.data.result;
};
