var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"grid-form-areas cp-padding__b cp-padding__l cp-padding__r"},[_c('div',{staticClass:"column-grid-1-1 cp-flex"},[_c('span',{staticClass:"cp-text-light"},[_vm._v("Добавить")]),_c('radio-btn-group',{staticClass:"flex cp-margin__l--s",attrs:{"data":_vm.operationType},on:{"click":_vm.updateOperationType}})],1),_c('div',{staticClass:"cp-flex flex-column",class:{
                'column-grid-1-6': _vm.customer || (_vm.sellerType && _vm.sellerType.current.id === 'sellerAuctionHouse'),
                'column-grid-1-1': _vm.isGalleryOrPainter,
                'full-height': _vm.isGalleryOrPainter,
            }},[_c('div',{staticClass:"cp-flex justify-between"},[_c('div',{staticClass:"cp-flex align-start"},[_c('input-block',{staticClass:"j_number_mask_wrapper cp-width--144",attrs:{"data":{ ..._vm.sum, value: _vm.getNumberFormatted(_vm.sum.value) }},on:{"input":_vm.updateSum}})],1),(_vm.$route.name === 'user_balance')?_c('div',{staticClass:"cp-margin__l--s"},[_c('input-suggestions',{class:{ hidden: _vm.orderIsHidden },attrs:{"data":{ ..._vm.order, value: _vm.getNumberFormatted(_vm.order.value) }},on:{"input":_vm.updateSearchOrder,"change":_vm.updateSearchOrder,"click":_vm.updateSelectedOrder}})],1):_c('div',{staticClass:"cp-margin__l--s flex-grow"},[(_vm.catalog)?_c('input-suggestions',{class:{
                            hidden:
                                (_vm.sellerType && _vm.sellerType.current.id !== 'sellerAuctionHouse') ||
                                (_vm.seller && !_vm.seller.current.id),
                        },attrs:{"data":_vm.catalog},on:{"input":_vm.updateSearchCatalog,"change":_vm.updateSearchCatalog,"click":_vm.updateSelectedCatalog}}):_vm._e()],1)]),_c('div',{staticClass:"cp-flex"},[_c('textarea-block',{attrs:{"data":_vm.comment},on:{"input":_vm.updateComment}})],1),_c('btn-submit',{class:{ 'cp-margin__t--small': _vm.isGalleryOrPainter },attrs:{"class-add":"cd-btn-primary cd-btn-radius-50 cp-margin__t--auto align-self-start","disabled":_vm.isSubmitBlocked}},[_vm._v("Добавить")])],1),(_vm.details)?_c('div',{staticClass:"column-grid-7-12"},[(_vm.order)?_c('div',{staticClass:"cp-flex justify-between align-baseline cp-padding__b-10"},[_c('div',{staticClass:"cp-width--half align-self cp-margin__l--form",class:{ hidden: !_vm.order.current.id }},[(_vm.sellerLink && Object.values(_vm.sellerLink).length)?_c('router-link',{attrs:{"to":{
                            name: _vm.sellerLink.type,
                            params: { [_vm.sellerLink.paramName]: _vm.sellerLink.id },
                        }}},[_vm._v(" "+_vm._s(_vm.sellerLink.name)+" ")]):_vm._e()],1)]):_vm._e(),(_vm.details)?_c('div',{staticClass:"relative cp-margin__l--form",class:{ invisible: !_vm.isDetailsVisible }},[(_vm.isLoading)?_c('loading'):_vm._e(),_vm._l((_vm.details),function(value){return _c('div',{key:value.id,staticClass:"cp-flex justify-between cp-padding__b-10 cp-padding__b-10"},[_c('span',[_vm._v(_vm._s(value.title))]),_c('span',{staticClass:"cp-margin__l--small whitespace-nowrap"},[_vm._v(_vm._s(_vm.getNumberFormatted(value.value))+" ₽")])])})],2):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }