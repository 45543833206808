import API from '@/admin/config/api-routers';
import { makeGetRequest, makeFormRequest } from '@/api/helpers';

export const getTransactionsList = async (currentPage: number, sort: string, filter: string, items: number) => {
    const result = await makeGetRequest(
        API.transaction.getList + `?page=${currentPage}&items=${items}&${sort}${filter}`,
    );

    return result.data.result.table;
};

export const createTransaction = async (data: any) => {
    const result = await makeFormRequest(API.transaction.create, data);

    return result;
};

export const cancelTransaction = async (transactionId: number) => {
    const result = await makeFormRequest(API.transaction.cancel + `${transactionId}`, {});

    return result;
};

export const getTransactionOperationNames = async () => {
    const result = await makeGetRequest(API.transaction.operationNames);

    return result.data.result.table;
};
