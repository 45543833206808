
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LocalizationLayout extends Vue {
    language = 'ru';

    setLanguage(lang: string): void {
        this.language = lang;
    }
}
