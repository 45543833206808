var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mr-table-main-block__wrapper mr-table-lots"},[_c('div',{ref:"scrollbar_bottom",staticClass:"mr-table-block__wrapper"},[(_vm.statusModel && _vm.isShowSelect)?_c('div',{staticClass:"lots-table-select"},[_c('select-custom',{staticClass:"cp-width--220 slim",attrs:{"data":_vm.statusModel,"items":_vm.statusModel.list,"current":_vm.statusModel.current},on:{"click":_vm.updateStatus}}),_c('btn-submit',{class:{ invisible: !_vm.isShowApply },attrs:{"class-add":"cd-btn-primary cd-btn-radius-50 slim"},on:{"click":_vm.apply}},[_vm._v(" Применить ")])],1):_vm._e(),_c('div',{staticClass:"mr-table-block__table"},[_c('table',{ref:"table",staticClass:"mr-table__wrapper"},[_c('table-title',{attrs:{"titles":_vm.titles,"hidden-columns":_vm.hidden_columns,"sort-fields":_vm.sort_fields,"is-status-column":false,"align-right-column":_vm.alignRightColumn},on:{"toggle-sort":_vm.toggleSort,"click":function($event){return _vm.actionHandler('check-all')}}}),_c('tbody',{staticClass:"cp-table-tbody__mask"}),(_vm.isTotal && _vm.rows && Object.values(_vm.rows).length > 0)?_c('tbody',{staticClass:"cp-table-tbody__mask-total"}):_vm._e(),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id,class:{
                            'not-active': row.is_active_row === false,
                            bold: row.is_bold,
                            checked: row.checked,
                            'error': _vm.errorIds.includes(row.id),
                        }},_vm._l((row),function(value,id){return _c('td',{key:id,class:{
                                'actions-wrapper': id === '_actions',
                                hidden: _vm.hidden_columns.includes(id),
                                small: id === 'id',
                                'align-right': _vm.alignRightColumn.includes(id),
                            },attrs:{"colspan":_vm.colspan && id === _vm.colspan.id ? _vm.colspan.span : 1}},[(id === 'name')?_vm._t("default",function(){return [_c('router-link',{staticClass:"cp-table__item-link",attrs:{"to":{ name: 'lot', params: { sellerType: row.sellerType, lotId: row.id } }},domProps:{"innerHTML":_vm._s(value)}})]}):(
                                    _vm.actionsHandler.additional && Object.keys(_vm.actionsHandler.additional).includes(id)
                                )?_vm._t("default",function(){return [_c('span',{class:{ 'cp-cursor-pointer': value },on:{"click":function($event){return _vm.actionHandler(id, row.id)}}},[(value)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})],2)]}):(id === '_actions')?_vm._t("default",function(){return _vm._l((value),function(name){return _c('span',{key:name,staticClass:"mr-admin-actions-icons",class:name},[_c('div',{staticClass:"mr-fake-checkbox__wrapper"},[_c('label',{staticClass:"mr-cursor-pointer"},[(name === 'checkbox')?_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":row.checked},on:{"change":function($event){return _vm.actionHandler(name, row.id)}}}):_vm._e(),_c('span',{staticClass:"check-mark"})])])])})}):(_vm.createdAtNames.includes(id))?_vm._t("default",function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.parseDate(value))+" ")])]}):_vm._t("default",function(){return [(value !== null)?_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}):_vm._t("default",function(){return [_vm._v(" - ")]})]})],2)}),0)}),0)],1)]),(_vm.actionPagination && Object.values(_vm.pagination).length && _vm.pagination)?_c('pagination-block',{attrs:{"data":_vm.pagination,"action":_vm.actionPagination,"items":_vm.itemsPagination},on:{"select-amount":_vm.selectAmount}}):_vm._e(),(_vm.isBottomTable && _vm.rows && Object.values(_vm.rows).length > 0)?_c('div',{staticClass:"cp-table-bottom__wrapper"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }