
import { Prop, Mixins, Component, Watch } from 'vue-property-decorator';
import StatisticsMixin from './StatisticsMixin';
import { getStatistics } from '@/admin/api/statistics';

@Component
export default class StatisticsComponent extends Mixins(StatisticsMixin) {
    @Prop() updated!: number;

    balance: string | number = 0;
    statistics: { [key: string]: { name: string; value: string | number; type: string } } = {
        number_auctions_placed: {
            name: 'Размещено каталогов в данный момент',
            value: 0,
            type: 'auction_house',
        },
        number_lots_placed: {
            name: 'Размещено лотов в данный момент',
            value: 0,
            type: '',
        },
        lots_sold: {
            name: 'Продано лотов',
            value: 0,
            type: '',
        },
        amount: {
            name: 'Общая сумма с проданных лотов',
            value: '',
            type: '',
        },
        received_avg_from_lot: {
            name: 'В среднем получено с одного лота',
            value: 0,
            type: '',
        },
        avg_price: {
            name: 'Средняя стоимость лота',
            value: '',
            type: '',
        },
    };

    initStatistics(): void {
        this.getStatistics();
    }

    setInfo(data: { [key: string]: number | string }): void {
        this.balance = +data.balance % 1 !== 0 ? (+data.balance).toFixed(2) : data.balance;

        Object.keys(data).forEach((key) => {
            if (this.statistics[key] == null) {
                return;
            }

            let value = data[key];

            if (+data[key] % 1 !== 0) {
                value = (+data[key]).toFixed(2);
            }

            if (key.indexOf('avg') !== -1 || key === 'amount') {
                value = `${value} ₽`;
            }

            this.statistics[key].value = value;
        });
    }

    async getStatistics(): Promise<void> {
        const result = await getStatistics(this.type, +this.entityId, this.filterString);

        this.setInfo(result);
    }

    @Watch('updated')
    onStatisticsUpdated() {
        this.getStatistics();
    }
}
