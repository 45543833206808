<template>
    <div class="mr-custom-editor__wrapper" :class="data.error.class">
        <span class="mr-input-default__title">{{ data.title }}</span>

        <ckeditor
            v-model="editorData"
            :editor="editor"
            :config="editorConfig"
            @input="updateValue"
            @ready="onEditorReady"
            @focusout="updateValue"
        ></ckeditor>

        <span class="mr-error-message">{{ data.error.message }}</span>
    </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@nektorandom/ckeditor5-full-custom/build/ckeditor';
import MyUploadAdapter from '@/plugins/editor/uploadAdapter/index.js';

// eslint-disable-next-line require-jsdoc
function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader, window.localStorage.tokenAdmin);
    };
}

export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: ['data'],
    data() {
        return {
            editor: ClassicEditor,
            editorData: this.data.value,
            editorConfig: {
                extraPlugins: [MyCustomUploadAdapterPlugin],
                // ckfinder: {
                //     // uploadUrl: 'api/ckfinder/connector', // this should be:
                //     uploadUrl: 'https://mirofs.demo.code-pilots.ru/api/admin/ckfinder/connector?command=QuickUpload&type=Images&responseType=json',
                //     options: {
                //         resourceType: 'Images',
                //         // This must go to the `options`:
                //         connectorPath: '/api/admin/ckfinder/connector',
                //     },
                // },
                mediaEmbed: {
                    previewsInData: true,
                },
                language: 'ru',
            },
        };
    },
    methods: {
        updateValue(params) {
            this.$emit('input', params);
            // this.$store.dispatch(this.data.action, this.editorData, {root: true});
            // if (this.data.errorHandler) {
            //     this.$store.dispatch(this.data.errorHandler, {index: this.data.key, field: this.data}, {root: true});
            // }
        },
        onEditorReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui
                .getEditableElement()
                .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());

            this.editorData = this.data.value;
        },
    },
};
</script>

<style lang="scss">
// @import './../../assets/scss/templates/modules/editor.scss';
</style>
