import { getCsrfToken } from './utils';

export default class MyUploadAdapter {
    constructor(loader, authToken) {
        this.loader = loader;
        this.authToken = authToken;
    }

    /**
     * @returns {obj} void
     */
    upload() {
        return this.loader.file.then((file) => {
            return new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            });
        });
    }

    /**
     * @returns {obj} void
     */
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    /**
     * @returns {obj} void
     */
    _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        if (!process.env.VUE_APP_API_URL?.length) {
            return;
        }
        xhr.open(
            'POST',
            `${process.env.VUE_APP_API_URL}/api/admin/ckfinder/connector?command=QuickUpload&type=images&responseType=json`,
            true,
        );
        xhr.setRequestHeader('Authorization', `Bearer ${this.authToken}`);
        xhr.responseType = 'json';
    }

    /**
     * Initializes XMLHttpRequest listeners.
     *
     * @private
     * @param {Function} resolve Callback function to be called when the request is successful.
     * @param {Function} reject Callback function to be called when the request cannot be completed.
     * @param {File} file File instance to be uploaded.
     * @returns {obj} void
     */
    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericError = 'Cannot upload file:' + ` ${file.name}.`;

        xhr.addEventListener('error', () => reject(genericError));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;

            if (!response || !response.uploaded) {
                return reject(
                    response && response.error && response.error.message ? response.error.message : genericError,
                );
            }

            resolve({
                default: location.origin + response.url,
            });
        });

        // Upload progress when it's supported.
        /* istanbul ignore else */
        if (xhr.upload) {
            xhr.upload.addEventListener('progress', (evt) => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    /**
     * Prepares the data and sends the request.
     *
     * @private
     * @param {File} file File instance to be uploaded.
     * @returns {obj} void
     */
    _sendRequest(file) {
        // Prepare form data.
        const data = new FormData();
        data.append('upload', file);
        // data.append('ckCsrfToken', getCsrfToken());

        // Send request.
        this.xhr.send(data);
    }
}
