import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getLotsList, getLotsStatuses } from '@/admin/api/lots';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import FilterModel from './lotsFilter';
import LotsFilterEntity from './lotsFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterDateParamsInterface, FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { prepareTable } from '@/admin/lib/Lots';

const MODULE_NAME = 'GalleryLots';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class GalleryLotsModule extends PageBaseModule {
    filter: Filter;
    galleryId = 0;
    statuses: { id: string; name: string }[] = [];

    constructor(module: GalleryLotsModule) {
        super(module);

        const page = new PageEntity();
        page.setSortName('galleryLotsSort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new FilterModel();
        this.filter = getModule(LotsFilterEntity);
        this.filter.setFilterName('galleryLotsFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_GALLERY_ID(id: number) {
        this.galleryId = id;
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]) {
        this.statuses = list;
    }

    @Mutation
    UPDATE_ROW_CHECKED(id: number) {
        const table = this.pageSettings.table as TableInterface;
        table.rows = table.rows.map((row) => {
            if (row.id === id) {
                row.checked = !row.checked;
            }

            return row;
        });

        table.titles.forEach((current, index) => {
            if (current.name === 'check' && current.value === true) {
                table.titles[index].value = false;
            }
        });

        this.pageSettings.table = table;
    }

    @Action({ rawError: true })
    toggleCheckAll() {
        const table = this.pageSettings.table as TableInterface;
        let value = false;

        const titles = table.titles.map((title) => {
            if (title.name === 'check') {
                title.value = !title.value;
                value = title.value;
            }

            return title;
        });

        const rows = table.rows.map((row) => {
            row.checked = value;

            return row;
        });

        table.titles = titles;
        table.rows = rows;

        this.SET_TABLE(table);
    }

    @Action({ rawError: true })
    async init(id: string) {
        this.context.commit('SET_GALLERY_ID', Number(id));
        this.setDefaultSort();

        this.context.commit('SET_STATUSES', await getLotsStatuses());

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        let filter = `&filters[0][id]=sellerGallery&filters[0][value]=${this.galleryId}`;
        filter += this.filter.filterSettings.filter;

        if (!sort && filter.length > 0) {
            filter = `&${filter}`;
        }

        const result = await getLotsList(
            this.pageSettings.pageCurrent,
            sort,
            filter,
            +this.pageSettings.pageAmountItems,
        );

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await prepareTable(table, this.statuses);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_TABLE', {});
        this.SET_STATUSES([]);
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }
}

export default getModule(GalleryLotsModule);
