import { Vue, Component, Prop } from 'vue-property-decorator';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

@Component
export default class ImageInputMixin extends Vue {
    @Prop({ required: false }) readonly data?: any;
    @Prop({ required: false }) readonly entity?: string;

    get imageStyle(): string {
        let imageStyle = '';
        if (this.data.value) {
            imageStyle = `background: url(${this.data.value}) center center / cover no-repeat;`;
        }

        return imageStyle;
    }

    get imagePreviewStyle(): string {
        let imagePreviewStyle = '';
        if (this.data.preview) {
            imagePreviewStyle = `background: url(${this.data.preview}) center center / cover no-repeat;`;
        }

        return imagePreviewStyle;
    }

    loadImage($event: Event): void {
        const target = $event.target as HTMLInputElement;
        const minWidth = this.data.minWidth;
        const minHeight = this.data.minHeight;

        if (!target.files) {
            return;
        }

        const imageFile = target.files[0];

        if (!imageFile.name.match(/.(jpg|jpeg|png)$/i)) {
            ResponseHandlerModule.showNotify({ message: 'Ошибка! Неверный формат файла', type: 'fail' });

            return;
        }

        const fr = new FileReader();

        fr.onload = () => {
            const img = new Image();

            img.onload = () => {
                if (img.width < minWidth || img.height < minHeight) {
                    ResponseHandlerModule.showNotify({
                        message: `Изображение не должно быть меньше ${minWidth}x${minHeight} пикселей`,
                        type: 'fail',
                    });

                    return;
                }

                this.$emit('load-image', target?.files ? target?.files[0] : null);
            };

            img.src = fr.result as string;
        };

        fr.readAsDataURL(imageFile);
    }
}
