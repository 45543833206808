import { languageIcons, languagesCodes } from '@/constants';
import { Input } from '@/lib/formFactory/input.interface';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import InputBlock from './InputMain.vue';
import TextareaBlock from '@/components/form/Textarea.vue';
import BtnLocalization from '@/components/buttons/BtnLocalization.vue';
import { LanguageType } from '@/interfaces/localization';

@Component({
    components: {
        InputBlock,
        TextareaBlock,
        BtnLocalization,
    },
})
export default class InputLocalizationMixin extends Vue {
    @Prop({ required: true }) data!: Input;
    @Prop({ default: 'name' }) fieldName!: string;
    @Prop({ default: false }) isTextarea!: boolean;
    @PropSync('language', { required: false }) languageModel!: LanguageType;

    languages = languagesCodes;
    icons = languageIcons;
    localization: Record<string, { [key: string]: string }> = {};

    get currentValue(): string {
        if (this.data.localization && this.data.localization[this.languageModel]) {
            return this.data.localization[this.languageModel];
        }

        return '';
    }

    set currentValue(value: string) {
        this.$emit('input', { lang: this.languageModel, value });
    }

    get component(): typeof TextareaBlock | typeof InputBlock {
        return this.isTextarea ? TextareaBlock : InputBlock;
    }

    onInput(value: string): void {
        this.currentValue = value;
    }

    switchLanguage(): void {
        const currLangIndex = this.languages.findIndex((lang) => lang === this.languageModel);
        const nextLanguage = this.languages[currLangIndex + 1] || this.languages[0];

        if (this.languageModel) {
            this.languageModel = nextLanguage;
        }
    }

    created(): void {
        this.languages.forEach((lang) => {
            let value = '';

            if (this.data.localization && this.data.localization[lang]) {
                value = this.data.localization[lang];
            }

            this.localization[lang] = {
                [this.fieldName]: value,
            };
        });
    }
}
