
import { Input } from '@/lib/formFactory/input.interface';
import { Prop, Component, Vue } from 'vue-property-decorator';

const INPUT_DEFAULT_VALUE: Input = {
    key: 'login',
    type: 'text',
    title: 'Логин',
    placeholder: 'Введите имя',
    value: '',
    required: true,
    size: 250,
    disabled: false,
    autocomplete: '',
    mask: {
        regex: '[a-zA-ZА-Яа-я+\\s]+',
        placeholder: ' ',
        minChars: 1,
    },
    error: {
        class: '',
        message: '',
    },
    validation: {
        empty: 'Поле не заполнено',
        incorrect: 'Некоректное имя',
    },
};

@Component
export default class InputMain extends Vue {
    @Prop({ required: true, default: () => INPUT_DEFAULT_VALUE }) data!: Input;
    @Prop() additionalData!: Record<string, any>;
    @Prop() disabled!: boolean;

    isLabelActive = false;

    get valueModel(): string {
        return this.data.value;
    }

    set valueModel(value: string) {
        if (this.additionalData != null) {
            this.$emit('input', { value, param: this.additionalData });

            return;
        }

        this.$emit('input', value);
    }

    addClassIn(): void {
        this.isLabelActive = true;
    }

    removeClassIn(): void {
        this.isLabelActive = false;
    }
}
