import { getAllCountries } from '@/admin/api/country';
import { getAllCities } from '@/admin/api/city';
import { CityItem } from '@/interfaces/city.interface';
import { getAllStatuses } from '@/admin/api/status';
import { Filter } from '../layouts/page/filter.interface';

export const initCountryFilter = async () => {
    const list = await getAllCountries();
    const newList = list.map((item: { id: number; name: string }) => {
        return {
            id: item.id,
            value: item.name,
        };
    });

    return newList;
};

export const filterCitiesByCountryId = (list: CityItem[], countryId: number | string) => {
    const citiesByCountry = list
        .filter((item: CityItem) => item.country.id == countryId)
        .map((item) => {
            return {
                id: item.id,
                value: item.name,
            };
        });

    return citiesByCountry;
};

export const initCities = async () => {
    const list = await getAllCities();

    return list;
};

export const initCityFilter = async (moduleName: string, list: CityItem[]) => {
    if (!window.localStorage[`${moduleName}FilterCountry`]) {
        return [];
    }

    const countryId = JSON.parse(window.localStorage[`${moduleName}FilterCountry`]).id;
    if (countryId === 0) {
        return [];
    }

    const citiesByCountry = await filterCitiesByCountryId(list, countryId);

    return citiesByCountry;
};

export const initStatusFilter = async () => {
    const statuses: { id: string; name: string }[] = await getAllStatuses();
    const result = statuses.map((status) => {
        return {
            id: status.id,
            value: status.name,
        };
    });

    return result;
};

export const updateCountryFilter = (
    cities: any[],
    selected: { key: string; value: { id: number; value: string } },
    filter: Filter,
) => {
    filter.updateSelect(selected);

    const citiesByCountry = cities
        .filter((city) => city.country.id === selected.value.id)
        .map((city) => {
            return {
                id: city.id,
                value: city.name,
            };
        });

    filter.updateSelect({ key: 'city', value: { id: 0, value: '' } });
    filter.updateSelectList({ key: 'city', list: citiesByCountry });
};

export const clearCountryAndCityFilters = (filter: Filter) => {
    filter.clearSelect('country');
    filter.clearSelect('city');
    filter.updateSelectList({ key: 'city', list: [] });
};
