
import Layout from '@/admin/layouts/Default.vue';
import { Vue, Component } from 'vue-property-decorator';

import Profile from './profile/Main.vue';
import Lots from './_lots.vue';
import Users from './_users.vue';
import Orders from './_orders.vue';
import Balance from './_balance.vue';

import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import GalleryModule from '@/admin/store/sellers/galleries';
import GalleryEntity from '@/admin/store/sellers/galleries/entity';
import { API_URL } from '@/constants';

@Component({
    components: {
        Layout,
        Profile,
        Lots,
        Users,
        Orders,
        Balance,
        TitleReturn,
        TabsNav,
    },
})
export default class GalleryMain extends Vue {
    returnTitleData = {
        icon: 'bank',
        title: this.galleryName,
    };

    get galleryName() {
        return GalleryEntity.galleryName;
    }

    get tabsNav() {
        return GalleryModule.tabsNav;
    }

    get currentTab() {
        if (this.$route.name != null) {
            return this.$route.name;
        }

        return '';
    }

    get publicLink() {
        if (this.$route.name === 'gallery_crate') {
            return '';
        }

        return `${API_URL}/galleries/${this.$route.params.galleryId}`;
    }

    async initTitle(): Promise<void> {
        if (this.$route.name === 'gallery_create') {
            this.returnTitleData = GalleryEntity.titleReturnData.create;

            return;
        }

        await GalleryEntity.initCardNameById(this.$route.params.galleryId);
        this.returnTitleData = GalleryEntity.titleReturnData.edit;
    }

    returnBefore() {
        this.$router.push({ name: 'galleries' });
    }

    async created() {
        this.initTitle();
    }
}
