export const tabsNav = {
    gallery: {
        id: 'gallery',
        name: 'Профиль',
        template: 'gallery',
        pathName: 'gallery',
        icon: 'store',
        iconSize: '16',
    },
    gallery_lots: {
        id: 'gallery_lots',
        name: 'Лоты',
        template: 'gallery_lots',
        pathName: 'gallery_lots',
        icon: 'pencil',
        iconSize: '16',
    },
    gallery_orders: {
        id: 'gallery_orders',
        name: 'Заказы',
        template: 'gallery_orders',
        pathName: 'gallery_orders',
        icon: 'bank',
        iconSize: '16',
    },
    gallery_users: {
        id: 'gallery_users',
        name: 'Пользователи админпанели',
        template: 'gallery_users',
        pathName: 'gallery_users',
        icon: 'user',
        iconSize: '16',
    },
    gallery_balance: {
        id: 'gallery_balance',
        name: 'Баланс',
        template: 'gallery_balance',
        pathName: 'gallery_balance',
        icon: 'bank',
        iconSize: '16',
    },
};
