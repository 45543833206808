<template>
    <transition name="modal">
        <div class="mr-modal-back__wrapper mr-modal-title-type mr-modal-filter">
            <div class="mr-modal__overlay">
                <div class="mr-modal__dark"></div>

                <div class="mr-modal__wrapper">
                    <div class="mr-modal-entry__wrapper">
                        <div class="mr-modal-title__wrapper">
                            <div class="mr-modal__buttons-wrapper">
                                <div v-if="additionalIcon" class="mr-modal__button" @click="additionalAction">
                                    <icon-font
                                        class="mr-modal__button-icon"
                                        size="11"
                                        :icon="additionalIcon.iconName"
                                    />
                                </div>
                                <div class="mr-modal__button" @click="closeModal">
                                    <icon-font class="mr-modal__button-icon" size="11" icon="close" />
                                </div>
                            </div>

                            <div class="mr-modal-title">
                                <div class="mr-modal-title__text" v-html="title"></div>
                            </div>
                        </div>

                        <div class="mr-modal-main-block__wrapper">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import IconFont from '@/components/icons/IconFont.vue';

export default {
    components: {
        IconFont,
    },
    props: {
        title: {
            type: String,
        },
        additionalIcon: {
            type: Object,
        },
    },
    methods: {
        closeModal() {
            this.$emit('click', '');
        },
        additionalAction() {
            this.$emit(this.additionalIcon.eventType);
        },
    },
};
</script>
